const PercentageSVG = () => (
	<svg
		xmlns="http://www.w3.org/2000/svg"
		width="16"
		height="16"
		viewBox="0 0 16 16"
		fill="none"
	>
		<path
			d="M2.6665 13.3327L13.3332 2.66602"
			stroke="#8D8F92"
			strokeLinecap="round"
		/>
		<path
			d="M5.51169 3.15417C6.16256 3.80504 6.16256 4.86032 5.51169 5.5112C4.86081 6.16207 3.80553 6.16207 3.15466 5.5112C2.50379 4.86032 2.50379 3.80504 3.15466 3.15417C3.80553 2.5033 4.86081 2.5033 5.51169 3.15417Z"
			stroke="#8D8F92"
		/>
		<path
			d="M12.8452 10.4882C13.4961 11.139 13.4961 12.1943 12.8452 12.8452C12.1943 13.4961 11.139 13.4961 10.4882 12.8452C9.83728 12.1943 9.83728 11.139 10.4882 10.4882C11.139 9.83728 12.1943 9.83728 12.8452 10.4882Z"
			stroke="#8D8F92"
		/>
	</svg>
);

export default PercentageSVG;
