const CalendarSVG = () => {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width="16"
			height="16"
			viewBox="0 0 16 16"
			fill="none"
		>
			<path
				d="M7.33301 8.66797H10.6663M5.33301 8.66797H5.33899M8.66634 11.3346H5.33301M10.6663 11.3346H10.6603"
				stroke="#8D8F92"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M12 1.33203V2.66536M4 1.33203V2.66536"
				stroke="#8D8F92"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M1.6665 8.16213C1.6665 5.25729 1.6665 3.80485 2.50125 2.90243C3.336 2 4.6795 2 7.3665 2H8.63317C11.3202 2 12.6637 2 13.4984 2.90243C14.3332 3.80485 14.3332 5.25729 14.3332 8.16213V8.50453C14.3332 11.4094 14.3332 12.8618 13.4984 13.7643C12.6637 14.6667 11.3202 14.6667 8.63317 14.6667H7.3665C4.6795 14.6667 3.336 14.6667 2.50125 13.7643C1.6665 12.8618 1.6665 11.4094 1.6665 8.50453V8.16213Z"
				stroke="#8D8F92"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M2 5.33203H14"
				stroke="#8D8F92"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
		</svg>
	);
};

export default CalendarSVG;
