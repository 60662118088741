import { useState } from 'react';
import useTimer from 'hooks/useTimer';

const hiddenSlideStyles: React.CSSProperties = {
	position: 'absolute',
	height: '22rem',
	top: '7rem',
	opacity: 0,
};

const shownSlideStyles: React.CSSProperties = {
	position: 'absolute',
	height: '22rem',
	top: '7rem',
	opacity: 1,
};

interface Slide {
	title?: string;
	url?: string;
}

interface MobileTimedSliderProps {
	slides: Slide[];
}

const MobileTimedSlider = ({ slides }: MobileTimedSliderProps) => {
	const [currentIndex, setCurrentIndex] = useState(0);
	const goToNext = () => {
		if (currentIndex < slides.length - 1) {
			setCurrentIndex(currentIndex + 1);
		} else {
			setCurrentIndex(0);
		}
	};

	const { pause, resume } = useTimer({
		interval: 5000,
		onTick: goToNext,
	});

	const goToSlide = (slideIndex: number) => {
		if (currentIndex !== slideIndex) {
			setCurrentIndex(slideIndex);
		}
	};

	const mouseOverNavigation = () => {
		pause();
	};
	const mouseLeaveNavigation = () => {
		resume();
	};

	return (
		<div className="md:hidden w-full flex p-2 pb-4 flex-col items-center justify-center gap-2">
			{slides.map((slide, slideIndex) => (
				<div key={slideIndex}>
					<div className="flex p-2 pb-4 flex-col items-center">
						<img
							src={slide.url}
							alt={slide.title}
							className="transition-all duration-700 border-[#040404] border-[1rem] rounded-xl"
							style={
								currentIndex === slideIndex
									? shownSlideStyles
									: hiddenSlideStyles
							}
						/>
					</div>
				</div>
			))}
			<div className="Navigation absolute top-[28rem] flex w-[17rem] pt-2 pb-6 justify-center items-center gap-2 bg-[#040404] rounded-lg">
				{slides.map((_, slideIndex) => (
					<div
						key={`navigation-${slideIndex}`}
						onClick={() => goToSlide(slideIndex)}
						className="w-16 h-1 relative cursor-pointer justify-center content-center"
						onMouseOver={mouseOverNavigation}
						onMouseLeave={mouseLeaveNavigation}
					>
						<div>
							<div className="Rectangle828059 h-1 left-0 top-0 absolute bg-zinc-700 rounded-lg my-2 w-16 " />
							<div
								className="Rectangle828061 h-1 left-0 top-0 absolute bg-white rounded-lg my-2 transition-all duration-500"
								style={{
									width: currentIndex >= slideIndex ? '4rem' : '0',
								}}
							/>
						</div>
					</div>
				))}
			</div>
		</div>
	);
};

export default MobileTimedSlider;
