/* eslint-disable @typescript-eslint/no-explicit-any */
import axios from 'axios';
import { RoutePath } from 'index';
import { logError } from 'utils/logger';

const axiosInstance = axios.create({
	withCredentials: true,
	baseURL: process.env.REACT_APP_API_URL,
	headers: {
		'Content-Type': 'application/json',
	},
	xsrfCookieName: 'csrftoken',
	xsrfHeaderName: 'X-CSRFToken',
});

const redirect401Interceptor = axiosInstance.interceptors.response.use(
	(response) => response,
	(error) => {
		// if (error?.response?.status === 401 || error?.response?.status === 403) {
		// 	logError(error);

		// 	// redirect to login page
		// 	// if not in the RoutePath.LOGIN route path
		// 	if (window.location.pathname !== RoutePath.LOGIN)
		// 		window.location.href = RoutePath.LOGIN;
		// }
		// return error;
		const originalRequest = error.config;

		// Check if the error status is 401
		if (error?.response?.status === 401 || error?.response?.status === 403) {
			// eslint-disable-next-line @typescript-eslint/strict-boolean-expressions
			if (!originalRequest._retry) {
				originalRequest._retry = true;
				return axiosInstance(originalRequest);
			}
		} else {
			originalRequest._retry = false;
		}

		// If the error status is 403 or the retry flag is set, redirect to login page
		if (error?.response?.status === 401 || error?.response?.status === 403) {
			logError(
				new Error('Unauthenticated request', {
					cause: error,
				})
			);

			// Redirect to login page if not already on the login route
			if (window.location.pathname !== RoutePath.LOGIN)
				window.location.href = RoutePath.LOGIN;
		}

		return error;
	}
);

export default axiosInstance;

export { redirect401Interceptor };
