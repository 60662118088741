import { HeadingWithSupportingText } from 'components/HeadingWithSupportingText';
import { HomeFooter } from 'layout/HomeFooter';
import { Header } from 'layout/Header';
import { ProgressBar } from 'components/ProgressBar';
import { useEffect, useState } from 'react';
import { UserListItem } from 'components/UserListItem';
import { useNavigate } from 'react-router-dom';
import { RoutePath } from 'index';

const LoadingLimit = 1.3;

const LoadingTeamsFromSlack = (): React.ReactElement => {
	const [percentage, setPercentage] = useState(0.0);
	const navigate = useNavigate();

	useEffect(() => {
		// update percentage every 25ms
		// clear interval if percentage is 1
		const interval = setInterval(() => {
			setPercentage((percentage) => {
				if (percentage >= LoadingLimit) {
					clearInterval(interval);
				}
				return percentage + 0.01;
			});
		}, 25);

		// clear interval on unmount
		return () => {
			clearInterval(interval);
		};
	}, []);

	useEffect(() => {
		if (percentage >= LoadingLimit) {
			navigate(RoutePath.ADD_MANAGERS);
		}
	}, [percentage, navigate]);

	return (
		<div className="w-full">
			<Header />
			<ProgressBar percentage={percentage} />
			<div className="flex flex-col items-center px-2 gap-8 my-14">
				<HeadingWithSupportingText
					heading="Bringing your team to Squadds..."
					supportingText="Searching for your Slack team members and bringing them onboard"
					styles={{
						supportingText: 'mx-8',
					}}
				/>
			</div>
			<div className="w-11/12 m-auto mb-24">
				<UserListItem
					image={'/images/slack-user.png'}
					title={'Squadds Teamplayers'}
					subtitle={'Getting ready deployed to Squadds.'}
				/>
			</div>
			<HomeFooter>&nbsp;</HomeFooter>
		</div>
	);
};

export default LoadingTeamsFromSlack;
