import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useMemo } from 'react';
import { selectOKR, selectOKRs, useOKRsState } from 'redux/slices/okrs';
import { AppDispatch } from 'redux/store';
import { useParams } from 'react-router-dom';

const useSelectedOKR = () => {
	const dispatch = useDispatch<AppDispatch>();
	const params = useParams();
	const { selectedOKR, selectOKRStatus, okrs, error } = useSelector(
		useOKRsState()
	);

	useEffect(() => {
		if (selectedOKR === null || selectedOKR === undefined) {
			void dispatch(selectOKRs());
		}
	}, [dispatch, selectedOKR]);

	// select okr if params.okr is provided and not selected
	useEffect(() => {
		if (params.okr === undefined || params.okr === '')
			throw new Error('No okr id provided');
		if (selectedOKR === null || selectedOKR === undefined) {
			void dispatch(
				selectOKR({
					okrId: params.okr,
				})
			);
		}
	}, [params.okr, dispatch, selectedOKR]);

	const selectedOKRId = useMemo(() => selectedOKR?.id, [selectedOKR]);
	const okrIndex = useMemo(() => {
		return okrs.map((okr) => okr.id).indexOf(selectedOKRId) + 1;
	}, [selectedOKRId, okrs]);

	return {
		selectedOKR,
		selectOKRStatus,
		okrIndex,
		okrs,
		error,
	};
};

export default useSelectedOKR;
