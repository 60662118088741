import MetricField from 'components/Forms/MetricField';
import React, { useCallback, useEffect } from 'react';
import { handleNumericInput } from 'utils';

interface MetricsCardProps {
	titleRef: React.RefObject<HTMLTextAreaElement>;
	initialValueRef: React.RefObject<HTMLInputElement>;
	metricTypeRef: React.RefObject<HTMLInputElement>;
	targetValueRef: React.RefObject<HTMLInputElement>;
	placeholder?: string;
}

const MetricsCard: React.FC<MetricsCardProps> = ({
	titleRef,
	initialValueRef,
	metricTypeRef,
	targetValueRef,
	placeholder = 'Type your milestone... ex: Reach 1M students',
}) => {
	const handleAutoSize = useCallback(() => {
		if (titleRef.current != null) {
			titleRef.current.style.height = 'auto';
			titleRef.current.style.height = `${titleRef.current.scrollHeight}px`;
		}
	}, [titleRef]);

	useEffect(() => {
		handleAutoSize();
	}, [titleRef, handleAutoSize]);

	useEffect(() => {
		titleRef.current?.focus();
	}, [titleRef]);

	return (
		<div>
			<div className="flex flex-col gap-0">
				<textarea
					ref={titleRef}
					className="w-full px-3 resize-none overflow-hidden rounded-tl-md rounded-tr-md shadow border border-[#35373c] bg-[#1b1c1f] text-white text-base font-normal font-inter focus:ring-0 focus:border-[#35373c]"
					placeholder={placeholder}
					onInput={handleAutoSize}
				/>

				<div className="flex flex-row bg-[#1b1c1f] text-white justify-between items-center">
					<div className="w-3/5 bg-[#1b1c1f] rounded-bl-md border-t-0 shadow border border-[#35373c] focus:ring-0 focus:border-[#35373c] justify-between items-center inline-flex pr-1">
						<input
							ref={initialValueRef}
							type="text"
							className="w-3/4 bg-transparent focus:ring-0 focus:border-[#35373c] text-white font-normal font-inter leading-normal border-0"
							placeholder="Initial"
							onInput={handleNumericInput}
						/>

						<MetricField metricValueRef={metricTypeRef} />
					</div>
					<input
						type="text"
						className="w-1/2 bg-[#1b1c1f] text-white font-normal font-inter rounded-br-md border-t-0 border-l-0 shadow border border-[#35373c] focus:ring-0 focus:border-[#35373c]"
						ref={targetValueRef}
						placeholder="Target"
						onInput={handleNumericInput}
					/>
				</div>
			</div>
		</div>
	);
};

export default MetricsCard;
