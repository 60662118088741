export const HomeFooter = ({
	children,
}: {
	children?: React.ReactNode;
}): React.ReactElement => {
	return (
		<div className="h-60">
			<img
				className="w-full bottom-0"
				src="/images/botton-bg-squad.png"
				alt="footer"
			/>
		</div>
	);
};
