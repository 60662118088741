import useSelectedOKR from 'hooks/useSelectedOKR';
import { RoutePath } from 'index';
import { useNavigate } from 'react-router-dom';

interface BreadcrumbV2Props {
	title?: string;
}

const BreadcrumbV2 = (props: BreadcrumbV2Props): React.ReactElement => {
	const { title } = props;
	const { okrIndex, selectedOKR } = useSelectedOKR();
	const navigate = useNavigate();

	const navigateToOKRDetails = () => {
		if (
			selectedOKR === undefined ||
			selectedOKR === null ||
			selectedOKR.id === undefined ||
			selectedOKR.id === null
		)
			return;

		navigate(RoutePath.OKR_DETAILS.replace(':okr', selectedOKR?.id));
	};

	return (
		<div className="Placeholder flex flex-row gap-1">
			<div
				className="text-gray-500 w-full text-xs font-normal font-['Inter'] leading-normal cursor-pointer truncate flex flex-row gap-1"
				onClick={navigateToOKRDetails}
			>
				O{okrIndex} - {selectedOKR?.objective}{' '}
				{title !== null && title !== undefined && (
					<>
						<span>&gt;</span>
						<div className="text-white text-xs font-normal font-['Inter'] leading-normal truncate">
							{title}
						</div>
					</>
				)}
			</div>
		</div>
	);
};

export default BreadcrumbV2;
