import { RoutePath } from 'index';
import { Navigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useIsAuthenticated, useAuthState } from 'redux/slices/auth';
import { Loader } from 'components/loader/Loader';

interface AuthenticatedRouteProps {
	children: React.ReactElement;
}

export const AuthenticatedRoute = ({
	children,
}: AuthenticatedRouteProps): React.ReactElement => {
	const isAuthenticated = useSelector(useIsAuthenticated());
	const fetchStatus = useSelector(useAuthState()).fetchStatus;

	if (fetchStatus === 'idle' && !isAuthenticated) {
		return <Navigate to={RoutePath.LOGIN} />;
	}

	if (fetchStatus === 'loading') {
		return <Loader />;
	}

	return children;
};
