import { useCallback, useEffect } from 'react';

interface ObjectiveInputProps {
	contentRef: React.RefObject<HTMLTextAreaElement>;
	placeholder?: string;
	initialValue?: string;
}

const ObjectiveInput = (props: ObjectiveInputProps): React.ReactElement => {
	const { placeholder = '', contentRef } = props;

	const handleAutoSize = useCallback(() => {
		if (contentRef.current != null) {
			contentRef.current.style.height = 'auto';
			contentRef.current.style.height = `${contentRef.current.scrollHeight}px`;
		}
	}, [contentRef]);

	useEffect(() => {
		contentRef.current?.focus();
	}, [contentRef]);

	useEffect(() => {
		handleAutoSize();
	}, [handleAutoSize]);

	return (
		<div>
			<div className="flex items-center rounded-md border border-[#1B1C1F] bg-[#1B1C1F] shadow-sm">
				<textarea
					ref={contentRef}
					className="w-full bg-[#1B1C1F] border-none resize-none overflow-hidden focus:border-none focus:outline-none text-[#6B7280] font-inter text-base font-normal focus:ring-0 focus:border-0"
					placeholder={placeholder}
					onChange={handleAutoSize}
				/>
			</div>
		</div>
	);
};

export default ObjectiveInput;
