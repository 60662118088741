import useClickOutside from 'hooks/useClickOutsideComponent';
import MoneySVG from 'icons/MoneySVG';
import NumberSVG from 'icons/NumberSVG';
import PercentageSVG from 'icons/PercentageSVG';
import { useRef, useState } from 'react';

interface MetricFieldProps {
	metricValueRef: React.RefObject<HTMLInputElement>;
}

const MetricField = ({ metricValueRef }: MetricFieldProps) => {
	const componentRef = useRef<HTMLDivElement>(null);
	const [isSelecting, setIsSelecting] = useState<boolean>(false);
	const [metric, setMetric] = useState<'123' | '%' | '$'>('123');

	useClickOutside(componentRef, () => setIsSelecting(false));

	const toggleSelect = () => {
		setIsSelecting(!isSelecting);
	};

	const handleMetricSelect = (recurrenceType: '123' | '%' | '$') => {
		setMetric(recurrenceType);
		setIsSelecting(false);
	};

	return (
		<div
			ref={componentRef}
			className="Tag w-fit p-1 cursor-pointer bg-zinc-800 rounded-lg border border-neutral-700 justify-start items-center gap-1 inline-flex"
		>
			<div className="Content px-1 justify-start items-center gap-1 flex">
				<input ref={metricValueRef} type="hidden" value={metric} />
				<div className="UserStory w-4 h-4 relative" onClick={toggleSelect}>
					{metric === '123' && <NumberSVG />}
					{metric === '%' && <PercentageSVG />}
					{metric === '$' && <MoneySVG />}
				</div>
				<div className="Owner text-neutral-400 text-xs font-normal font-['Inter'] leading-tight">
					<div className={isSelecting ? 'relative' : 'hidden'}>
						<div
							className="Dropdown w-32 z-10 overflow-y-auto absolute -left-16 top-4 p-1 bg-zinc-900 rounded-lg shadow flex-col justify-start items-start gap-1 inline-flex"
							style={{
								scrollbarWidth: 'thin',
								scrollbarColor: '#D9D9D9 #1F2937',
							}}
						>
							<div
								className={`DropdownOptions self-stretch pl-2 pr-1 py-2 rounded-md justify-start items-center gap-[42px] inline-flex cursor-pointer ${
									metric === '123' ? 'bg-[#2A2C32]' : ''
								}`}
								onClick={() => handleMetricSelect('123')}
							>
								<div className="SelectCompanySize text-[#d2d3e0] text-sm font-normal font-inter">
									# Number
								</div>
							</div>
							<div
								className={`DropdownOptions self-stretch pl-2 pr-1 py-2 rounded-md justify-start items-center gap-[42px] inline-flex cursor-pointer ${
									metric === '%' ? 'bg-[#2A2C32]' : ''
								}`}
								onClick={() => handleMetricSelect('%')}
							>
								<div className="SelectCompanySize text-[#d2d3e0] text-sm font-normal font-inter">
									% Percentage
								</div>
							</div>
							<div
								className={`DropdownOptions self-stretch pl-2 pr-1 py-2 rounded-md justify-start items-center gap-[42px] inline-flex cursor-pointer ${
									metric === '$' ? 'bg-[#2A2C32]' : ''
								}`}
								onClick={() => handleMetricSelect('$')}
							>
								<div className="SelectCompanySize text-[#d2d3e0] text-sm font-normal font-inter">
									$ Money
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default MetricField;
