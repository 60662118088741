import { configureStore } from '@reduxjs/toolkit';

import auth from './slices/auth';
import okrs from './slices/okrs';
import checkpoints from './slices/checkpoints';
import company from './slices/company';

export const store = configureStore({
	reducer: {
		auth,
		okrs,
		checkpoints,
		company,
	},
});

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;

// Inferred type: {okrs: OkrsState, auth: AuthState}
export type AppDispatch = typeof store.dispatch;
