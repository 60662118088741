// implement a react component that is a React.DetailedHTMLProps<React.ButtonHTMLAttributes<HTMLButtonElement>, HTMLButtonElement>

import React from 'react';

interface IProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
	children: React.ReactNode;
	disabled?: boolean;
	fullWidth?: boolean;
}

const BlueButton = (props: IProps): React.ReactElement => {
	const { disabled, children, fullWidth = false, ...rest } = props;

	let className =
		'px-3 py-2 text-center text-white text-sm font-medium font-inter leading-tight  bg-indigo-600 rounded-md justify-center items-center inline-flex';

	className += fullWidth ? ' w-full' : 'w-fit';

	if (disabled === true) {
		className += ' opacity-50 ';
	}

	return (
		<button className={className} {...rest}>
			{children}
		</button>
	);
};

export default React.memo(BlueButton);
