const TeamSVG = () => {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width="16"
			height="16"
			viewBox="0 0 16 16"
			fill="none"
		>
			<path
				d="M13.8493 12C14.3489 12 14.7462 11.6857 15.103 11.2461C15.8333 10.3463 14.6342 9.6272 14.1769 9.27507C13.712 8.91707 13.1929 8.71427 12.6667 8.66667M12 7.33333C12.9205 7.33333 13.6667 6.58714 13.6667 5.66667C13.6667 4.74619 12.9205 4 12 4"
				stroke="#8D8F92"
				strokeLinecap="round"
			/>
			<path
				d="M2.15048 12C1.65094 12 1.25362 11.6857 0.896869 11.2461C0.166562 10.3463 1.36562 9.6272 1.82294 9.27507C2.28782 8.91707 2.80689 8.71427 3.33317 8.66667M3.6665 7.33333C2.74603 7.33333 1.99984 6.58714 1.99984 5.66667C1.99984 4.74619 2.74603 4 3.6665 4"
				stroke="#8D8F92"
				strokeLinecap="round"
			/>
			<path
				d="M5.38952 10.0728C4.70834 10.494 2.92232 11.3541 4.01012 12.4303C4.54151 12.956 5.13334 13.332 5.87741 13.332H10.1233C10.8673 13.332 11.4591 12.956 11.9905 12.4303C13.0783 11.3541 11.2923 10.494 10.6111 10.0728C9.01372 9.0851 6.98692 9.0851 5.38952 10.0728Z"
				stroke="#8D8F92"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M10.3337 5.0013C10.3337 6.28997 9.28899 7.33464 8.00033 7.33464C6.71166 7.33464 5.66699 6.28997 5.66699 5.0013C5.66699 3.71264 6.71166 2.66797 8.00033 2.66797C9.28899 2.66797 10.3337 3.71264 10.3337 5.0013Z"
				stroke="#8D8F92"
			/>
		</svg>
	);
};

export default TeamSVG;
