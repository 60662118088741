interface AvatarCircleProps {
	member: {
		profile_image: string;
		display_name?: string;
		name?: string;
	};
	style?: React.CSSProperties;
	displayName?: boolean;
}

const AvatarCircle = ({
	member,
	style,
	displayName = false,
}: AvatarCircleProps) => (
	<div
		className="Avatar w-4 h-4 justify-center items-center flex"
		style={style}
	>
		<img
			className="AvatarCircle w-4 h-4 rounded-full border border-[#7b61ff]"
			src={member.profile_image}
		/>
		{displayName && (
			<div className="Name text-white text-sm font-medium font-['SF Pro Display'] leading-tight tracking-tight">
				{member.display_name ?? member.name ?? ''}
			</div>
		)}
	</div>
);

export default AvatarCircle;
