import { useState } from 'react';

export const UserListItem = ({
	image,
	title,
	subtitle,
	onClick,
}: {
	image: string;
	title: string;
	subtitle: string;
	onClick?: () => void;
}): React.ReactElement => {
	const [isActive, setIsActive] = useState<boolean>(false);

	let className =
		'flex flex-row items-center h-20 mb-4 rounded-lg bg-gray-bg cursor-pointer';
	if (isActive) {
		className += ' ring-inset ring-2 ring-blue-bg';
	}

	const handleClick = () => {
		if (onClick !== undefined) {
			setIsActive(!isActive);
			onClick();
		}
	};

	return (
		<div onClick={handleClick} className={className}>
			<img className="w-10 h-10 m-5 rounded-md" src={image} />
			<div>
				<div className="text-sm font-medium text-gray-900">{title}</div>
				<div className="text-sm font-normal text-gray-500">{subtitle}</div>
			</div>
		</div>
	);
};
