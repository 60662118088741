import * as Yup from 'yup';
import {
	followerSchema,
	krSchema,
	okrFormSchema,
} from 'pages/authenticated/goals/schemas';
import { InferType } from 'yup';

export const checkpointSchema = Yup.object().shape({
	id: Yup.string().optional().nullable(),
	createdAt: Yup.string().optional().nullable(),
	author: Yup.string().optional().nullable(),
	author_name: Yup.string().optional().nullable(),
	author_profile_image: Yup.string().optional().nullable(),
	author_profile_title: Yup.string().optional().nullable(),
	kr: Yup.string().required('Key Result is required'),
	current: Yup.number().optional().nullable(),
	previous: Yup.number().optional().nullable(),
	progress_in_unit: Yup.string().optional().nullable(),
	is_truncated: Yup.boolean().default(false),
	target: Yup.number().required('Target value is required'),
	checkpoint: Yup.string().required('Checkpoint is required'),
	checkpoint_truncated: Yup.string().optional().nullable(),
});

export type OKR = InferType<typeof okrFormSchema>;
export type KR = InferType<typeof krSchema>;
export type Follower = InferType<typeof followerSchema>;
export enum FollowerStatus {
	ACTIVE = 'active',
	INACTIVE = 'inactive',
	DISABLED = 'disabled',
	REMOVED = 'removed',
}
export type MetricType = '%' | '$' | '123';
export type CheckpointForm = InferType<typeof checkpointSchema>;

export interface ITeamMember {
	id: string;
	display_name: string;
	real_name: string;
	profile_image: string;
	profile_title: string;
	status: string;
}

export interface Page {
	title: string;
	route: string;
}

export interface Navigation {
	pages: Page[];
	current: string;
}
