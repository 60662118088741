export interface ProgressBarProps {
	percentage: number;
}

export const ProgressBar = ({
	percentage = 0,
}: ProgressBarProps): React.ReactElement => {
	percentage = Math.max(0, Math.min(1, percentage)); // Delimit percentage between 0 and 1
	const width = `${percentage * 100}%`; // Convert percentage to a width css property

	return (
		<div className="mx-auto w-11/12">
			<div className="w-full bg-gray-300 h-[1px] mb-4">
				<div
					className="bg-[#1B1C1F] h-[2px] mx-auto content-center"
					style={{ width }}
				></div>
			</div>
		</div>
	);
};
