import AvatarCircle from './AvatarCircle';

interface AvatarGroupProps {
	members: Array<{
		id: string;
		profile_image: string;
		display_name?: string;
		name?: string;
	}>;
	toggleSelect?: () => void;
	disabled?: boolean;
}

const AvatarGroup = ({
	members,
	toggleSelect,
	disabled = false,
}: AvatarGroupProps) => (
	<div
		onClick={disabled ? undefined : toggleSelect ?? undefined}
		className="AvatarGroup justify-start items-start flex"
	>
		{members.slice(0, 2).map((member, i) => (
			<AvatarCircle
				key={member.id}
				member={member}
				style={{ marginLeft: i !== 0 ? '-0.3rem' : '0' }}
			/>
		))}
		{members.length > 2 && (
			<div className="Avatar w-4 h-4 relative ml-[-0.3rem]">
				<div className="AvatarCircle w-4 h-4 left-0 top-0 absolute bg-black rounded-full border border-[#2a2c32]" />
				<div className="left-[5px] top-[5px] absolute text-[#d2d3e0] text-[4px] font-medium font-inter">
					+{members.length - 2}
				</div>
			</div>
		)}
	</div>
);

export default AvatarGroup;
