export interface HeadingWithSupportingTextProps {
	heading: string;
	supportingText?: string;
	styles?: {
		heading?: string;
		supportingText?: string;
	};
	children?: React.ReactNode;
}

export const HeadingWithSupportingText = ({
	heading,
	supportingText,
	styles,
	children,
}: HeadingWithSupportingTextProps): React.ReactElement => {
	return (
		<div className="flex flex-col items-center px-2 text-[#b2b3b7] text-lg font-normal font-inter leading-normal pt-6 text-center">
			<h1
				className={`font-semibold text-4xl text-center lg:max-w-xl${
					styles?.heading ?? ''
				}`}
			>
				{heading}
			</h1>
			{supportingText !== null && (
				<p
					className={`font-normal text-lg leading-6 text-center mt-6 ${
						styles?.supportingText ?? ''
					}`}
				>
					{supportingText}
				</p>
			)}
			{children}
		</div>
	);
};
