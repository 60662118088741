import { useState, useEffect } from 'react';

const useCodeFromUrl = () => {
	const [code, setCode] = useState<string | null>(null);

	useEffect(() => {
		const urlParams = new URLSearchParams(window.location.search);
		const code = urlParams.get('code');
		setCode(code);
	}, []);

	return code;
};

export default useCodeFromUrl;
