import { MetricType } from 'types';
import { formatDistanceToNow } from 'date-fns';

const USDollarFormat = new Intl.NumberFormat('en-US', {
	style: 'currency',
	currency: 'USD',
});

const NumberFormat = new Intl.NumberFormat('en-US', {
	style: 'decimal',
});

export const isEmailValid = (email: string) => {
	const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
	return emailRegex.test(email);
};

export const displayMetric = (
	value: number | null | undefined = 0,
	type: MetricType
) => {
	switch (type) {
		case '%':
			return `${value ?? 0}%`;
		case '$':
			return displayAsMoney(value ?? 0);
		case '123':
			return displayAsNumber(value ?? 0);
		default:
			return value ?? 0;
	}
};

export const displayAsMoney = (value: number) => {
	return USDollarFormat.format(value);
};

export const displayAsNumber = (value: number) => {
	return NumberFormat.format(value);
};

export const handleNumericInput = (e: React.FormEvent<HTMLInputElement>) => {
	const inputElement = e.currentTarget;
	const numericValue = inputElement.value.replace(/[^0-9.]/g, ''); // Allow digits and decimal points
	if (numericValue !== inputElement.value) {
		inputElement.value = numericValue;
	}
};

export const formatDatetimeToHumanReadable = (date: number | string | Date) => {
	return formatDistanceToNow(new Date(date), { addSuffix: true });
}