import { Header } from 'layout/Header';

const Container = (props: { children: React.ReactNode }) => {
	return (
		<div className="w-full">
			<Header />
			<div className="w-11/12 m-auto">
				<div className="left-0 mt-2 rounded-md shadow self-center m-1/2" />
				{props.children}
			</div>
		</div>
	);
};

export default Container;
