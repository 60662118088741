import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axiosInstance from 'data/fetcher';
import { RootState } from 'redux/store';
import { API_ROUTES } from 'routes';
import { CheckpointForm } from 'types';

// slice state type
interface CheckpointState {
	status?: 'idle' | 'adding' | 'success' | 'failed';
	checkpointListStatus?: 'idle' | 'loading' | 'failed';
	checkpoints: CheckpointForm[];
	hasNext: boolean;
	page: number;
	error?: string;
}

// slice initial state
const initialState: CheckpointState = {
	status: 'idle',
	checkpointListStatus: 'loading',
	checkpoints: [],
	hasNext: true,
	page: 1,
	error: undefined,
};

const addCheckpoint = createAsyncThunk(
	'checkpoints/add',
	async (checkpoint: CheckpointForm, thunkAPI) => {
		const response = await axiosInstance.post(
			API_ROUTES.CORE_CHECKPOINT_ADD,
			{
				...checkpoint,
			},
			{
				signal: thunkAPI.signal,
			}
		);

		return response.data.checkpoint;
	}
);

const listCheckpoints = createAsyncThunk(
	'checkpoints/list',
	async (
		{
			kr,
		}: {
			kr: string;
			cleanCheckpointList?: boolean;
		},
		thunkAPI
	) => {
		let url = API_ROUTES.CORE_CHECKPOINT_LIST.replace(':kr', kr);
		const page = (thunkAPI.getState() as RootState).checkpoints.page;
		if (page > 1) {
			url = url.concat(`?page=${page}`);
		}

		const response = await axiosInstance.get(url, {
			signal: thunkAPI.signal,
		});

		return response.data;
	}
);

const resetCheckpointState = createAsyncThunk('checkpoints/reset', async () => {
	return initialState;
});

// slice configuration
const checkpointsSlice = createSlice({
	name: 'checkpoints',
	initialState,
	reducers: {
		reset: () => initialState,
		toggleExpand: (state, action) => {
			const checkpointIndex = state.checkpoints.findIndex(
				(checkpoint) => checkpoint.id === action.payload
			);

			if (checkpointIndex === -1) return;

			state.checkpoints[checkpointIndex] = {
				...state.checkpoints[checkpointIndex],
				is_truncated: !state.checkpoints[checkpointIndex].is_truncated,
			};
		},
	},
	extraReducers: (builder) => {
		builder
			// add checkpoint cases
			.addCase(addCheckpoint.pending, (state) => {
				state.status = 'adding';
				state.error = undefined;
			})
			.addCase(addCheckpoint.fulfilled, (state) => {
				state.status = 'success';
			})
			.addCase(addCheckpoint.rejected, (state, action) => {
				state.status = 'failed';
				state.error = action.error.message;
			})
			.addCase(listCheckpoints.pending, (state, action) => {
				state.checkpointListStatus = 'loading';
				state.error = undefined;
				if (action.meta.arg.cleanCheckpointList === true) {
					state.checkpoints = [];
					state.page = 1;
				}
			})
			.addCase(listCheckpoints.fulfilled, (state, action) => {
				// filter out duplicates
				const checkpointIds = state.checkpoints.map(
					(checkpoint) => checkpoint.id
				);
				const filteredCheckpoints = action.payload.checkpoints.filter(
					(checkpoint: CheckpointForm) => !checkpointIds.includes(checkpoint.id)
				);

				state.checkpointListStatus = 'idle';
				state.checkpoints = [...state.checkpoints, ...filteredCheckpoints];
				state.hasNext = action.payload.meta.hasNext;
				if (action.payload.meta.hasNext === true) {
					state.page += 1;
				}
			})
			.addCase(listCheckpoints.rejected, (state, action) => {
				state.checkpointListStatus = 'failed';
				state.error = action.error.message;
			});
	},
});

// exporting actions
export { addCheckpoint, listCheckpoints, resetCheckpointState };
export const { reset, toggleExpand } = checkpointsSlice.actions;

// exporting selectors
export const useCheckpointsState = () => (state: RootState) => {
	return {
		checkpoints: state.checkpoints.checkpoints,
		checkpointListStatus: state.checkpoints.checkpointListStatus,
		hasNext: state.checkpoints.hasNext,
		page: state.checkpoints.page,
	};
};

export default checkpointsSlice.reducer;
