import AvatarCircle from 'components/avatars/AvatarCircle';
import axiosInstance from 'data/fetcher';
import useClickOutside from 'hooks/useClickOutsideComponent';
import OwnerSVG from 'icons/OwnerSVG';
import { useEffect, useRef, useState } from 'react';
import { API_ROUTES } from 'routes';
import { ITeamMember } from 'types';
import MemberDropdown from './MemberDropDown';

interface OwnerTagProps {
	ownerId?: string;
	onSelect: (selected: ITeamMember | undefined) => void;
}

const OwnerTag = (props: OwnerTagProps) => {
	const { ownerId, onSelect } = props;
	const [memberList, setMemberList] = useState<ITeamMember[]>([]);
	const [loading, setLoading] = useState<boolean>(false);
	const [isSelecting, setIsSelecting] = useState<boolean>(false);
	const [owner, setOwner] = useState<ITeamMember>();
	const componentRef = useRef<HTMLDivElement>(null);

	const onSelectItem = (item: ITeamMember) => {
		setOwner(item);
		onSelect(item);
		setIsSelecting(false);
	};

	const toggleSelect = () => {
		setIsSelecting(!isSelecting);
	};

	useClickOutside(componentRef, () => setIsSelecting(false));

	useEffect(() => {
		setLoading(true);
		const loadOwners = async () => {
			const { data } = await axiosInstance.get(API_ROUTES.CORE_MEMBERS_LIST);
			setMemberList(data.members);
			setLoading(false);
		};
		void loadOwners();
	}, []);

	useEffect(() => {
		if (ownerId !== undefined && ownerId !== null) {
			setOwner(memberList.find((member) => member.id === ownerId));
		}
	}, [memberList, ownerId]);

	return (
		<div
			ref={componentRef}
			className="Tag w-fit p-1 cursor-pointer bg-zinc-800 rounded-lg border border-neutral-700 justify-start items-center gap-1 inline-flex"
		>
			<div className="Content px-1 justify-start items-center gap-1 flex">
				<div className="UserStory w-4 h-4 relative" onClick={toggleSelect}>
					{owner !== undefined && owner !== null ? (
						<AvatarCircle member={owner} />
					) : (
						<OwnerSVG />
					)}
				</div>
				<div className="Owner text-neutral-400 text-xs font-normal font-['Inter'] leading-tight">
					<span onClick={toggleSelect} className='truncate'>
						{owner !== undefined && owner !== null
							? owner.display_name
							: 'Owner'}
					</span>
					{isSelecting && (
						<div className="relative">
							<MemberDropdown
								memberList={memberList}
								loading={loading}
								selectedMembers={
									owner !== undefined && owner !== null ? [owner] : []
								}
								toggleMemberSelection={onSelectItem}
							/>
						</div>
					)}
				</div>
			</div>
		</div>
	);
};

export default OwnerTag;
