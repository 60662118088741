import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axiosInstance from 'data/fetcher';
import { RootState } from 'redux/store';
import { API_ROUTES } from 'routes';

// slice state type
interface CompanyState {
	status?: 'idle' | 'loading' | 'success' | 'failed';
	error?: string;
	name: string;
	numberOfEmployees: number;
	logo: string;
}

// slice initial state
const initialState: CompanyState = {
	status: 'idle',
	error: undefined,
	name: '',
	numberOfEmployees: 0,
	logo: '',
};

const loadCompany = createAsyncThunk('company/load', async (_, thunkAPI) => {
	const response = await axiosInstance.get(API_ROUTES.COMPANY, {
		signal: thunkAPI.signal,
	});
	return response.data.company;
});

// slice configuration
const companySlice = createSlice({
	name: 'company',
	initialState,
	reducers: {
		reset: () => initialState,
	},
	extraReducers: (builder) => {
		builder
			// add checkpoint cases
			.addCase(loadCompany.pending, (state) => {
				state.status = 'loading';
				state.error = undefined;
			})
			.addCase(loadCompany.rejected, (state, action) => {
				state.status = 'failed';
				state.error = action.error.message;
				// add error logging here
			})
			.addCase(loadCompany.fulfilled, (state, action) => {
				state.status = 'success';
				state.name = action.payload.name;
				state.numberOfEmployees = action.payload.total_members;
				state.logo = action.payload.logo;
			});
	},
});

// exporting actions
export { loadCompany };
export const { reset } = companySlice.actions;

// exporting selectors
export const useCompanyState = () => (state: RootState) => state.company;

export default companySlice.reducer;
