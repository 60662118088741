import { useDispatch, useSelector } from 'react-redux';
import { useEffect } from 'react';
import { selectKR, selectOKRs, useKRState } from 'redux/slices/okrs';
import { AppDispatch } from 'redux/store';
import { useParams } from 'react-router-dom';
import { listCheckpoints } from 'redux/slices/checkpoints';

const useSelectedKR = () => {
	const dispatch = useDispatch<AppDispatch>();
	const params = useParams();

	useEffect(() => {
		void dispatch(selectOKRs());
	}, [dispatch]);

	useEffect(() => {
		if (
			params.okr === undefined ||
			params.okr === '' ||
			params.kr === undefined ||
			params.kr === ''
		) {
			return;
		}

		void dispatch(
			selectKR({
				okrId: params.okr,
				krId: params.kr,
			})
		).then(() => {
			return dispatch(
				listCheckpoints({
					kr: params.kr as string,
					cleanCheckpointList: true,
				})
			);
		});
	}, [dispatch, params]);

	const { selectedKR, selectKRStatus, error } = useSelector(useKRState());

	return {
		selectedKR,
		selectKRStatus,
		error,
	};
};

export default useSelectedKR;
