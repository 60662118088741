import React from 'react';
import ReactDOM from 'react-dom/client';
import 'index.css';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import Terms from 'pages/Terms';
import Privacy from 'pages/Privacy';
import LoadingTeamsFromSlack from 'pages/LoadingTeamsFromSlack';
import Login from 'pages/Login';
import { OAuth } from 'pages/OAuth';
import AuthenticationProvider from 'components/authentication/AuthenticationProvider';
import { AuthenticatedRoute } from 'components/authentication/AuthenticatedRoute';
import Dashboard from 'pages/authenticated/Dashboard';
import { Provider } from 'react-redux';
import { store } from './redux/store';
import Subscription from 'pages/authenticated/settings/Subscription';
import * as Sentry from '@sentry/react';
import AddGoal from 'pages/authenticated/goals/AddGoal';
import AddMilestone from 'pages/authenticated/goals/AddMilestone';
import OKRDetails from 'pages/authenticated/goals/OKRDetails';
import KRDetails from 'pages/authenticated/goals/KRDetails';
import initAmplitude from 'utils/amplitude';

initAmplitude();

Sentry.init({
	dsn: 'https://de7fc8c548e5d98d8cc8e10f4e225a10@o4505332688814080.ingest.sentry.io/4505857444478976',
	integrations: [
		new Sentry.BrowserTracing({
			// Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
			tracePropagationTargets: [/^https:\/\/webapi\.squadds\.co/],
		}),
		new Sentry.Replay({
			maskAllText: false,
			blockAllMedia: false,
		}),
	],
	// Performance Monitoring
	tracesSampleRate: 1.0, //  Capture 100% of the transactions
	// Session Replay
	replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
	replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});

// enum for route paths
export enum RoutePath {
	HOME = '/',
	LOGIN = '/login',
	LOADING_TEAMS_FROM_SLACK = '/loading-from-slack',
	ADD_MANAGERS = '/add-managers',
	DASHBOARD = '/',
	DASHBOARD_EMPTY = '/goals-empty',
	TERMS = '/terms-of-service',
	PRIVACY = '/privacy-policy',
	OAUTH = '/oauth',
	ADD_GOAL = '/goals/new',
	ADD_MILESTONE = '/goals/:okr/new-milestone',
	UPDATE_GOAL = '/goals/:okr/update',
	OKR_DETAILS = '/goals/:okr/',
	KR_DETAILS = '/goals/:okr/milestones/:kr',
	KR_CHECKPOINT = '/goals/:okr/checkpoint',
	SETTINGS_SUBSCRIPTION = '/settings/subscription',
}

const routes = [
	{
		path: RoutePath.TERMS,
		element: <Terms />,
	},
	{
		path: RoutePath.PRIVACY,
		element: <Privacy />,
	},
	{
		path: RoutePath.OAUTH,
		element: <OAuth />,
	},
	{
		// TODO: create a landing page for the home route
		path: RoutePath.HOME,
		element: <Dashboard />,
	},
	{
		path: RoutePath.LOGIN,
		element: <Login />,
	},
];

const authenticatedRoutes = [
	{
		path: RoutePath.LOADING_TEAMS_FROM_SLACK,
		element: (
			<AuthenticationProvider>
				<AuthenticatedRoute>
					<LoadingTeamsFromSlack />
				</AuthenticatedRoute>
			</AuthenticationProvider>
		),
	},
	{
		path: RoutePath.DASHBOARD,
		element: (
			<AuthenticationProvider>
				<AuthenticatedRoute>
					<Dashboard />
				</AuthenticatedRoute>
			</AuthenticationProvider>
		),
	},
	{
		path: RoutePath.ADD_GOAL,
		element: (
			<AuthenticationProvider>
				<AuthenticatedRoute>
					<AddGoal header={true} />
				</AuthenticatedRoute>
			</AuthenticationProvider>
		),
	},
	{
		path: RoutePath.ADD_MILESTONE,
		element: (
			<AuthenticationProvider>
				<AuthenticatedRoute>
					<AddMilestone header={true} />
				</AuthenticatedRoute>
			</AuthenticationProvider>
		),
	},
	{
		path: RoutePath.OKR_DETAILS,
		element: (
			<AuthenticationProvider>
				<AuthenticatedRoute>
					<OKRDetails header />
				</AuthenticatedRoute>
			</AuthenticationProvider>
		),
	},
	{
		path: RoutePath.KR_DETAILS,
		element: (
			<AuthenticationProvider>
				<AuthenticatedRoute>
					<KRDetails header />
				</AuthenticatedRoute>
			</AuthenticationProvider>
		),
	},
	{
		path: RoutePath.SETTINGS_SUBSCRIPTION,
		element: (
			<AuthenticationProvider>
				<AuthenticatedRoute>
					<Subscription />
				</AuthenticatedRoute>
			</AuthenticationProvider>
		),
	},
];

const router = createBrowserRouter([...routes, ...authenticatedRoutes]);

const root = ReactDOM.createRoot(
	document.getElementById('root') as HTMLElement
);

root.render(
	<React.StrictMode>
		<Provider store={store}>
			<RouterProvider router={router} />
		</Provider>
	</React.StrictMode>
);
