import React from 'react';
import { KR } from 'types';
import { displayMetric } from 'utils';

interface ProgressBarProps {
	kr: KR;
}

const max = (value: number, max: number) => {
	if (value > max) return max;
	return value;
}

const ProgressBar: React.FC<ProgressBarProps> = ({ kr }) => {
	return (
		<div className="KRProgress flex flex-col gap-2 w-full mx-1">
			<div className="w-full flex flex-row content-between justify-between">
				<div className=" text-white text-sm font-medium font-Inter">
					{displayMetric(kr.current, kr.type)}
				</div>
				<div className="TodaySTotalSales text-gray-500 text-xs font-normal font-['Inter']">
					{displayMetric(kr.target, kr.type)}
				</div>
			</div>
			<div className="w-full h-1 bg-[#595CD9]/20 rounded-md">
				<div
					className="bg-[#595CD9] h-1 rounded-md transition-all duration-1000"
					style={{ width: `${max(kr.progress ?? 0, 100)}%` }}
				></div>
			</div>
		</div>
	);
};

export default ProgressBar;
