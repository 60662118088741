import { useState, useEffect } from 'react';

// eslint-disable-next-line @typescript-eslint/no-empty-function
const useTimer = ({ interval = 1000, onTick = () => {} }) => {
	const [isPaused, setIsPaused] = useState(false);

	const pause = () => {
		setIsPaused(true);
	};
	const resume = () => {
		setIsPaused(false);
	};

	/**
	 * This effect centralizes the timer logic.
	 * It will run the onTick function every interval, unless the timer is paused.
	 * If the timer is paused the cycle will stop until it is
	 * resumed, restarting the interval from zero.
	 */
	useEffect(() => {
		const intervalFn = setInterval(() => {
			if (!isPaused) {
				onTick();
			}
		}, interval);
		return () => clearInterval(intervalFn);
	}, [isPaused, interval, onTick]);

	return { pause, resume, isPaused };
};

export default useTimer;
