import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { AppDispatch } from 'redux/store';
import {
	checkCredentials,
	useAuthState,
	useExpirationDate,
	useIsAuthenticated,
} from 'redux/slices/auth';
import consts from 'consts';
import * as amplitude from '@amplitude/analytics-browser';

/**
 * Authentication provider, which checks the credentials of the user
 * every time the expiration time is reached.
 */
const AuthenticationProvider: React.FC<React.PropsWithChildren> = ({
	children,
}) => {
	const dispatch = useDispatch<AppDispatch>();
	const isAuthenticated = useSelector(useIsAuthenticated());
	const authState = useSelector(useAuthState());
	const expiration = useSelector(useExpirationDate());

	useEffect(() => {
		if (authState.id !== null && authState.id !== '') {
			void amplitude.setUserId(authState.id);
			const identifyEvent = new amplitude.Identify();
			identifyEvent.setOnce('team', authState.company ?? '');
			identifyEvent.setOnce('name', authState.name ?? '');
			amplitude.identify(identifyEvent);
		}
	}, [authState.id, authState.company, authState.name]);

	useEffect(() => {
		void dispatch(checkCredentials());
	}, [dispatch]);

	useEffect(() => {
		if (!isAuthenticated || expiration === null) {
			return;
		}

		// check credentials every time the expiration time is reached

		// the expiration time is in UTC, so we need to convert it to local time
		// and then calculate the difference in milliseconds
		const expirationInMs = expiration.getTime() - new Date().getTime();

		// use Math.min to prevent overflows
		const timeout = Math.min(expirationInMs, consts.MAXIMUM_TIMEOUT);

		// check credentials after the timeout
		// this will also check credentials immediately if the timeout is 0
		const interval = setInterval(() => {
			void dispatch(checkCredentials());
		}, timeout);

		return () => clearInterval(interval);
	}, [dispatch, expiration, isAuthenticated]);

	return <React.Fragment>{children}</React.Fragment>;
};

export default AuthenticationProvider;
