import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axiosInstance from 'data/fetcher';
import { RootState } from 'redux/store';
import { API_ROUTES } from 'routes';

// slice state type
interface AuthState {
	fetchStatus?: 'idle' | 'loading' | 'failed';
	expiration: {
		year: number;
		month: number;
		day: number;
		hour: number;
		minute: number;
		second: number;
	} | null;
	id: string | null;
	name: string | null;
	company: string | null;
	error?: string;
}

// slice initial state
const initialState: AuthState = {
	fetchStatus: 'loading',
	expiration: null,
	id: null,
	name: null,
	company: null,
	error: undefined,
};

const checkCredentials = createAsyncThunk(
	'auth/checkCredentials',
	async (_, thunkAPI) => {
		const response = await axiosInstance.get(
			API_ROUTES.SLACK_OAUTH_CREDENTIALS_CHECK,
			{
				signal: thunkAPI.signal,
			}
		);
		return response.data;
	}
);

const postOAuthCallback = createAsyncThunk(
	'auth/postOAuthCallback',
	async (code: string, thunkAPI) => {
		const response = await axiosInstance.post(
			API_ROUTES.SLACK_OAUTH_CALLBACK,
			{
				code,
			},
			{
				signal: thunkAPI.signal,
			}
		);
		return response.data;
	}
);

const logout = createAsyncThunk('auth/logout', async (_, thunkAPI) => {
	const response = await axiosInstance.post(API_ROUTES.SLACK_OAUTH_LOGOUT, {
		signal: thunkAPI.signal,
	});
	return response.data;
});

// slice configuration
const authSlice = createSlice({
	name: 'auth',
	initialState,
	reducers: {},
	extraReducers: (builder) => {
		builder
			// checkCredentials cases
			.addCase(checkCredentials.pending, (state) => {
				state.fetchStatus = 'loading';
				state.error = undefined;
			})
			.addCase(checkCredentials.fulfilled, (state, action) => {
				state.fetchStatus = 'idle';
				state.id = action.payload.id;
				state.name = action.payload.name;
				state.company = action.payload.team;
				state.expiration = {
					year: action.payload.expiration.year,
					month: action.payload.expiration.month,
					day: action.payload.expiration.day,
					hour: action.payload.expiration.hour,
					minute: action.payload.expiration.minute,
					second: action.payload.expiration.second,
				};
			})
			.addCase(checkCredentials.rejected, (state, action) => {
				state.fetchStatus = 'idle';
				state.id = null;
				state.name = null;
				state.company = null;
				state.expiration = null;
				state.error = action.error.message;
			})
			// postOAuthCallback cases
			.addCase(postOAuthCallback.pending, (state) => {
				state.fetchStatus = 'loading';
				state.error = undefined;
			})
			.addCase(postOAuthCallback.fulfilled, (state, action) => {
				state.fetchStatus = 'idle';

				if (action.payload !== undefined && action.payload !== null) {
					state.expiration = {
						year: action.payload.user.expiration.year,
						month: action.payload.user.expiration.month,
						day: action.payload.user.expiration.day,
						hour: action.payload.user.expiration.hour,
						minute: action.payload.user.expiration.minute,
						second: action.payload.user.expiration.second,
					};
					state.id = action.payload.user.id;
					state.name = action.payload.user.name;
					state.company = action.payload.user.team;
				}
			})
			.addCase(postOAuthCallback.rejected, (state, action) => {
				state.fetchStatus = 'failed';
				state.id = null;
				state.name = null;
				state.company = null;
				state.expiration = null;
				state.error = action.error.message;
			})
			.addCase(logout.fulfilled, (state) => {
				state.fetchStatus = 'idle';
				state.id = null;
				state.name = null;
				state.company = null;
				state.expiration = null;
			})
			.addCase(logout.rejected, (state, action) => {
				state.fetchStatus = 'failed';
				state.id = null;
				state.name = null;
				state.company = null;
				state.expiration = null;
				state.error = action.error.message;
			});
	},
});

export { checkCredentials, postOAuthCallback, logout };

// exporting selectors
export const useAuthState = () => (state: RootState) => state.auth;
export const useIsAuthenticated = () => (state: RootState) => {
	const expirationDate = new Date(
		state.auth.expiration?.year ?? 0,
		state.auth.expiration?.month ?? 0,
		state.auth.expiration?.day ?? 0,
		state.auth.expiration?.hour ?? 0,
		state.auth.expiration?.minute ?? 0,
		state.auth.expiration?.second ?? 0
	);
	return state.auth.expiration !== null && expirationDate > new Date();
};
export const useName = () => (state: RootState) => state.auth.name;
export const useCompany = () => (state: RootState) => state.auth.company;
export const useExpirationDate = () => (state: RootState) => {
	const expirationDate = new Date(
		state.auth.expiration?.year ?? 0,
		state.auth.expiration?.month ?? 0,
		state.auth.expiration?.day ?? 0,
		state.auth.expiration?.hour ?? 0,
		state.auth.expiration?.minute ?? 0,
		state.auth.expiration?.second ?? 0
	);
	return state.auth.expiration !== null ? expirationDate : null;
};

export default authSlice.reducer;
