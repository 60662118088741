const MoneySVG = () => (
	<svg
		xmlns="http://www.w3.org/2000/svg"
		width="16"
		height="16"
		viewBox="0 0 16 16"
		fill="none"
	>
		<path
			d="M14.6668 8.00065C14.6668 11.6825 11.682 14.6673 8.00016 14.6673C4.31826 14.6673 1.3335 11.6825 1.3335 8.00065C1.3335 4.31875 4.31826 1.33398 8.00016 1.33398C11.682 1.33398 14.6668 4.31875 14.6668 8.00065Z"
			stroke="#8D8F92"
		/>
		<path
			d="M9.80696 6.70805C9.74089 6.19961 9.15709 5.37813 8.10736 5.37811C6.88763 5.37809 6.3744 6.05362 6.27026 6.39139C6.10779 6.84318 6.14029 7.77205 7.56996 7.87332C9.35709 7.99998 10.073 8.21092 9.98196 9.30465C9.89083 10.3983 8.89463 10.6346 8.10736 10.6092C7.32003 10.584 6.03192 10.2223 5.98193 9.24951M7.98243 4.66602V5.38053M7.98243 10.6027V11.3326"
			stroke="#8D8F92"
			strokeLinecap="round"
		/>
	</svg>
);

export default MoneySVG;
