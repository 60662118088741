import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { selectOKRs, useOKRsState } from 'redux/slices/okrs';
import { AppDispatch } from 'redux/store';
import { Welcome } from 'components/company/Welcome';
import AddGoal from './goals/AddGoal';
import { RoutePath } from 'index';
import { useNavigate } from 'react-router-dom';
import OKRList from './goals/OKRList';
import Container from './Container';
import { Loader } from 'components/loader/Loader';

const Dashboard = (): React.ReactElement => {
	const dispatch = useDispatch<AppDispatch>();
	const navigate = useNavigate();

	const { okrs, selectOKRSStatus, error } = useSelector(useOKRsState());

	useEffect(() => {
		void dispatch(selectOKRs());
	}, [dispatch]);

	useEffect(() => {
		if (selectOKRSStatus === 'failed' && error !== undefined) {
			navigate(RoutePath.LOGIN);
		}
	}, [selectOKRSStatus, error, navigate]);

	return (
		<Container>
			{selectOKRSStatus === 'idle' && okrs.length === 0 && (
			<Welcome />
			)}

			{selectOKRSStatus === 'loading' && (
				<div className="text-center h-screen">
					<Loader />
				</div>
			)}

			{selectOKRSStatus === 'idle' && okrs.length === 0 && (
				<>
					<div className="mt-4 flex flex-col items-center border border-[#35373C]  rounded-lg lg:w-3/4 m-auto">
						<AddGoal />
					</div>
				</>
			)}

			{selectOKRSStatus === 'idle' && okrs.length > 0 && <OKRList editable />}
		</Container>
	);
};

export default Dashboard;
