import Badge from 'components/Badge';
import BlueButton from 'components/BlueButton';
import { Loader } from 'components/loader/Loader';
import { RoutePath } from 'index';
import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { selectOKR, useOKRsState } from 'redux/slices/okrs';
import { AppDispatch } from 'redux/store';
import { OKR } from 'types';

interface OKRListProps {
	editable?: boolean;
}

const OKRList = (props: OKRListProps): React.ReactElement => {
	const navigate = useNavigate();
	const dispatch = useDispatch<AppDispatch>();

	const { okrs, selectOKRSStatus } = useSelector(useOKRsState());

	const addNewObjectiveHandler = useCallback(() => {
		navigate(RoutePath.ADD_GOAL);
	}, [navigate]);

	const navigateToOKRDetails = useCallback(
		(okr: OKR) => {
			if (
				okr === undefined ||
				okr === null ||
				okr.id === undefined ||
				okr.id === null
			)
				return;

			void dispatch(
				selectOKR({
					okrId: okr.id,
				})
			).then((args) => {
				navigate(`${RoutePath.OKR_DETAILS.replace(':okr', args.payload.id)}`);
			});
		},
		[navigate, dispatch]
	);

	if (selectOKRSStatus === 'loading') {
		return <Loader />;
	}

	return (
		<div className="w-full pb-6 ">
			{okrs.map((okr, i) => (
				<div
					key={okr.id}
					className="mt-4 flex flex-col items-center border border-[#35373C] rounded-lg lg:w-3/4 m-auto cursor-pointer"
					onClick={() => navigateToOKRDetails(okr)}
				>
					<div className="flex items-center justify-between w-full p-2 mx-auto ">
						<div className="flex items-center space-x-2">
							<Badge text={`O${i + 1}`} width="10" color="text-white" />
							{okr.owner_profile_image !== undefined &&
								okr.owner_profile_image !== null &&
								okr.owner_profile_image !== '' && (
									<img
										className="object-cover w-6 h-6 rounded-full"
										src={okr.owner_profile_image}
										alt="Owner"
									/>
								)}
						</div>
						<div className="flex-1 px-4 text-center text-white text-xs font-normal font-['Inter'] truncate">
							{okr.objective}
						</div>
						<Badge
							backgroundColor="#D1FAE5"
							color="text-[#065F46]"
							text={`0/${okr.krs?.length ?? 0}`}
						/>
					</div>
				</div>
			))}
			<div className="Divider w-ful h-px bg-neutral-700 border-neutral-700 my-4" />

			<div className="flex content-end justify-end ">
				<BlueButton type="button" onClick={addNewObjectiveHandler}>
					Set up a new objective
				</BlueButton>
			</div>
		</div>
	);
};

export default OKRList;
