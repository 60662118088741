import { useEffect } from 'react';

type ClickOutsideCallback = () => void;

/**
 * A custom hook to detect clicks outside a specified element.
 * @param ref - A React ref pointing to the element to monitor.
 * @param callback - A callback function to invoke when a click outside is detected.
 */
const useClickOutside = <T extends HTMLElement>(
	ref: React.RefObject<T>,
	callback: ClickOutsideCallback
) => {
	useEffect(() => {
		// Function to handle outside clicks
		const handleOutsideClick = (event: MouseEvent | TouchEvent) => {
			// Check if the click target is outside the element
			if (ref.current != null && !ref.current.contains(event.target as Node)) {
				callback();
			}
		};

		// Add event listeners
		document.addEventListener('mousedown', handleOutsideClick);
		document.addEventListener('touchstart', handleOutsideClick);

		// Cleanup event listeners on component unmount
		return () => {
			document.removeEventListener('mousedown', handleOutsideClick);
			document.removeEventListener('touchstart', handleOutsideClick);
		};
	}, [ref, callback]);
};

export default useClickOutside;
