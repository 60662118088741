import React from 'react';
import { Welcome } from 'components/company/Welcome';
import Container from 'pages/authenticated/Container';

const Subscription = (): React.ReactElement => {
	return (
		<Container>
			<Welcome />
			<div className="mb-4 flex flex-col items-center md:items-start border border-[#35373C] rounded-lg">
				<div className="font-inter text-sm text-white p-4">
					Current Subscription: <strong>BETA</strong>
				</div>
			</div>
		</Container>
	);
};

export default Subscription;
