import { HeadingWithSupportingText } from 'components/HeadingWithSupportingText';
import { Loader } from 'components/loader/Loader';
import { SlackButton } from 'components/SlackButton';
import useCodeFromUrl from 'hooks/useSlackCodeFromUrl';
import { RoutePath } from 'index';
import { useEffect, useLayoutEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import {
	postOAuthCallback,
	useAuthState,
	useIsAuthenticated,
} from 'redux/slices/auth';
import { AppDispatch } from 'redux/store';

const LoadingSlackAccount = () => {
	const dispatch = useDispatch<AppDispatch>();
	const navigate = useNavigate();

	const { fetchStatus } = useSelector(useAuthState());
	const isAuthenticated = useSelector(useIsAuthenticated());
	const code = useCodeFromUrl();

	useLayoutEffect(() => {
		if (code === null || code.length === 0) return;
		void dispatch(postOAuthCallback(code));
	}, [code, dispatch]);

	useEffect(() => {
		if (fetchStatus === 'idle' && isAuthenticated) {
			navigate(RoutePath.DASHBOARD);
		}
	}, [fetchStatus, isAuthenticated, navigate]);

	if (fetchStatus === 'failed') {
		return (
			<div className="flex flex-col items-center px-2 text-[#b2b3b7] text-lg font-normal font-inter leading-normal pt-6 text-center">
				<HeadingWithSupportingText
					heading="Something went wrong"
					supportingText="We couldn't verify your Slack account. Please try again."
				/>
				<SlackButton />
			</div>
		);
	}

	return <Loader />;
};

const RequestReAuthentication = () => {
	return (
		<HeadingWithSupportingText
			heading="Please authenticate with Slack to continue"
			supportingText="We didn't receive a code from Slack. Please try again."
		>
			<div className="mt-6">
				<SlackButton />
			</div>
		</HeadingWithSupportingText>
	);
};

export const OAuth = (): React.ReactElement => {
	const code = useCodeFromUrl();

	return (
		<div className="w-full ">
			{code === null || code.length === 0 ? (
				<RequestReAuthentication />
			) : (
				<LoadingSlackAccount />
			)}
		</div>
	);
};
