import { OKR } from 'types';

interface ProgressBadgeProps {
	okr: OKR;
}

const ProgressBadge = (props: ProgressBadgeProps) => {
	const { okr } = props;
	return (
		<div className="TextLink justify-end items-center gap-2 flex">
			<div className="Badge px-3 py-0.5 bg-emerald-100 rounded-2xl justify-center items-center flex">
				<div className="Text text-center text-emerald-800 text-sm font-medium font-['Inter'] leading-tight">
					{
						okr.krs?.filter(
							(x) =>
								x.progress !== undefined &&
								x.progress !== null &&
								x.progress >= 100
						).length
					}
					/{okr.krs?.length ?? 0}
				</div>
			</div>
		</div>
	);
};

export default ProgressBadge;
