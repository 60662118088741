// API routes
export const API_ROUTES = {
	SLACK_OAUTH_CALLBACK: '/slack/oauth/callback',
	SLACK_OAUTH_CREDENTIALS_CHECK: '/slack/oauth/credentials_check',
	SLACK_OAUTH_LOGOUT: '/slack/oauth/logout',
	CORE_MEMBERS_SEARCH: '/core/members/search',
	CORE_MEMBERS_LIST: '/core/members/list',
	CORE_MEMBER: '/core/members/:id',
	CORE_OKR_UPDATE: '/core/okrs/update',
	CORE_KR_UPDATE: '/core/okrs/:okr/update-kr',
	CORE_ACTIVE_OKRS: '/core/okrs',
	CORE_OKR: '/core/okrs/:okr',
	CORE_KR: '/core/okrs/:okr/krs/:kr',
	CORE_CHECKPOINT_ADD: '/core/checkpoints/add',
	CORE_CHECKPOINT_LIST: '/core/checkpoints/list/:kr',
	CORE_KR_FOLLOW: '/core/followers/toggle',
	COMPANY: '/core/company',
};
