import { ITeamMember } from 'types';

interface MemberDropdownProps {
	memberList: ITeamMember[];
	loading: boolean;
	selectedMembers: ITeamMember[];
	toggleMemberSelection: (member: ITeamMember) => void;
}

const MemberDropdown = ({
	memberList,
	loading,
	selectedMembers,
	toggleMemberSelection,
}: MemberDropdownProps) => (
	<div
		className="Dropdown  z-10 overflow-y-auto absolute -left-8 top-2 w-max p-1 bg-zinc-900 rounded-lg shadow flex-col justify-start items-start gap-1 inline-flex"
		style={{
			scrollbarWidth: 'thin',
			scrollbarColor: '#D9D9D9 #1F2937',
		}}
	>
		{loading && <div>loading...</div>}
		{memberList.map((member) => (
			<div
				key={member.id}
				onClick={() => toggleMemberSelection(member)}
				className={`DropdownList self-stretch px-2 py-1 justify-start items-center gap-2 inline-flex cursor-pointer ${
					selectedMembers.some((m) => m.id === member.id) ? 'bg-[#2A2C32]' : ''
				}`}
			>
				<img
					className="Avatar w-6 h-6 rounded-full"
					src={member.profile_image}
				/>
				<div className="text-white text-sm font-medium font-['SF Pro Display'] leading-5 tracking-tight">
					{member.display_name}
				</div>
			</div>
		))}
	</div>
);

export default MemberDropdown;
