import * as amplitude from '@amplitude/analytics-browser';
import { sessionReplayPlugin } from '@amplitude/plugin-session-replay-browser';

const initAmplitude = () => {
	const projectId =
		process.env.NODE_ENV === 'production'
			? '08144315d9371dc6bffd74271f7d499e'
			: '0411bc18d9a47dd15c1dbf34e4f67f05';

	// Create and Install Session Replay Plugin
	const sessionReplayTracking = sessionReplayPlugin({
		sampleRate: 100,
	});
	amplitude.add(sessionReplayTracking);

	amplitude.init(projectId, {
		autocapture: { elementInteractions: true },
	});
};

export default initAmplitude;
