import { RoutePath } from 'index';
import { useNavigate } from 'react-router-dom';

export interface BreadCrumbProps {
	links?: Array<{
		text: string;
		route: string;
	}>;
	rootTitle?: string;
	pageTitle?: string;
	disableRootNavigation?: boolean;
}

export const BreadCrumb = (props: BreadCrumbProps) => {
	const { links, rootTitle, pageTitle, disableRootNavigation = false } = props;
	const navigate = useNavigate();

	return (
		<nav className="flex px-2" aria-label="Breadcrumb">
			<ol className="inline-flex items-center space-x-1 md:space-x-3">
				<li className="inline-flex items-center">
					<button
						onClick={() => {
							if (disableRootNavigation) return;
							navigate(RoutePath.DASHBOARD);
						}}
						className="inline-flex items-center text-sm font-medium text-[#6B7280] "
					>
						<svg
							xmlns="http://www.w3.org/2000/svg"
							width="24"
							height="24"
							viewBox="0 0 24 24"
							fill="none"
						>
							<path
								d="M5.0249 21C5.04385 19.2643 5.04366 17.5541 5.0366 15.9209M5.0366 15.9209C5.01301 10.4614 4.91276 5.86186 5.19475 4.04271C5.5611 1.67939 9.39301 3.82993 13.9703 5.59842L16.0328 6.48729C17.5508 7.1415 19.7187 8.30352 18.7662 9.66084C18.3738 10.22 17.56 10.8596 16.0575 11.567L5.0366 15.9209Z"
								stroke="#6B7280"
								strokeWidth="1.5"
								strokeLinecap="round"
								strokeLinejoin="round"
							/>
						</svg>
						{rootTitle}
					</button>
				</li>
				{links !== undefined &&
					links.length > 0 &&
					links.map((link, index) => (
						<li key={`breadcrumb-${index}`}>
							<div className="flex items-center">
								<svg
									className="w-3 h-3 text-gray-400 mx-1"
									aria-hidden="true"
									xmlns="http://www.w3.org/2000/svg"
									fill="none"
									viewBox="0 0 6 10"
								>
									<path
										stroke="currentColor"
										strokeLinecap="round"
										strokeLinejoin="round"
										strokeWidth="2"
										d="m1 9 4-4-4-4"
									/>
								</svg>
								<button
									onClick={() => navigate(link.route)}
									className="ml-1 text-sm font-medium text-gray-700 hover:text-blue-600 md:ml-2 dark:text-gray-400 dark:hover:text-white"
								>
									{link.text}
								</button>
							</div>
						</li>
					))}
				{pageTitle !== undefined && (
					<li aria-current="page">
						<div className="flex items-center">
							<svg
								className="w-3 h-3 text-gray-400 mx-1"
								aria-hidden="true"
								xmlns="http://www.w3.org/2000/svg"
								fill="none"
								viewBox="0 0 6 10"
							>
								<path
									stroke="currentColor"
									strokeLinecap="round"
									strokeLinejoin="round"
									strokeWidth="2"
									d="m1 9 4-4-4-4"
								/>
							</svg>
							<span className="ml-1 text-sm font-medium text-gray-500 md:ml-2 dark:text-gray-400">
								{pageTitle}
							</span>
						</div>
					</li>
				)}
			</ol>
		</nav>
	);
};
