interface BadgeProps {
	text: string;
	color?: string;
	width?: string;
	backgroundColor?: string;
}

const Badge = (props: BadgeProps) => {
	const {
		text,
		color = 'text-black',
		backgroundColor = '#2A2C32',
		width = null,
	} = props;
	return (
		<div
			className={`bg-[${backgroundColor}] ${color} font-inter text-xs font-medium rounded-2xl flex items-center justify-center px-3 py-1 w-${
				width ?? 'auto'
			}`}
		>
			{text}
		</div>
	);
};

export default Badge;
