import React, { useCallback, useEffect } from 'react';
import { KR } from 'types';
import { handleNumericInput } from 'utils';

interface FollowUpFormProps {
	kr: KR;
	titleRef: React.RefObject<HTMLTextAreaElement>;
	valueRef: React.RefObject<HTMLInputElement>;
	placeholder?: string;
}

const FollowUpForm: React.FC<FollowUpFormProps> = ({
	kr,
	titleRef,
	valueRef,
	placeholder = 'Type your milestone... ex: Reach 1M students',
}) => {
	const handleAutoSize = useCallback(() => {
		if (titleRef.current != null) {
			titleRef.current.style.height = 'auto';
			titleRef.current.style.height = `${titleRef.current.scrollHeight}px`;
		}
	}, [titleRef]);

	useEffect(() => {
		handleAutoSize();
	}, [titleRef, handleAutoSize]);

	return (
		<div className="flex flex-col gap-0 w-full">
			<textarea
				ref={titleRef}
				className="w-full resize-none bg-transparent overflow-hidden border-none text-white text-base font-normal font-inter focus:ring-0 focus:border-none"
				placeholder={placeholder}
				onInput={handleAutoSize}
			/>

			<div className="flex p-2 rounded-md FormFieldsTextInput h-8 px-[13px] py-1 bg-[#1b1c1f] rounded-tr-lg rounded-br-lg shadow border border-[#35373c] justify-start items-center gap-2">
				<label htmlFor="followup-input" className="w-1/2 bg-transparent border-none text-white font-normal text-xs font-inter leading-normal mr-2 focus:outline-none focus:ring-0">
					Update milestone:
				</label>
				<input
					ref={valueRef}
					type="text"
					id='followup-input'
					placeholder={
						kr.current === undefined || kr.current === null
							? '0'
							: kr.current.toString()
					}
					className="w-1/2 bg-transparent border-none text-white font-normal text-xs font-inter leading-normal mr-2 focus:outline-none focus:ring-0 text-right"
					onInput={handleNumericInput}
				/>
			</div>
		</div>
	);
};

export default FollowUpForm;
