import React, { useCallback, useEffect } from 'react';
import Container from 'pages/authenticated/Container';
import useSelectedOKR from 'hooks/useSelectedOKR';
import ProgressBadge from 'components/okr/ProgressBadge';
import OKRIndexBadge from 'components/okr/OKRIndexBadge';
import ProgressBar from 'components/kr/ProgressBar';
import AvatarGroup from 'components/avatars/AvatarGroup';
import TutorialMessage from 'components/callouts/TutorialMessage';
import BlueButton from 'components/BlueButton';
import { useNavigate } from 'react-router-dom';
import { RoutePath } from 'index';
import { Loader } from 'components/loader/Loader';
import { useDispatch } from 'react-redux';
import { AppDispatch } from 'redux/store';
import { cleanKR } from 'redux/slices/okrs';
import { resetCheckpointState } from 'redux/slices/checkpoints';
import BreadcrumbV2 from 'components/BreadcrumbV2';

interface OKRDetailsProps {
	header?: boolean;
}

const OKRDetails = (props: OKRDetailsProps): React.ReactElement => {
	const { header } = props;
	const { selectedOKR, selectOKRStatus, okrs } = useSelectedOKR();
	const navigate = useNavigate();
	const dispatch = useDispatch<AppDispatch>();

	const addNewMilestoneHandler = useCallback(() => {
		if (
			selectedOKR === undefined ||
			selectedOKR === null ||
			selectedOKR.id === undefined ||
			selectedOKR.id === null
		)
			return;

		navigate(RoutePath.ADD_MILESTONE.replace(':okr', selectedOKR.id));
	}, [navigate, selectedOKR]);

	const onKRClick = useCallback(
		(krId?: string | null) => {
			if (
				selectedOKR === undefined ||
				selectedOKR === null ||
				selectedOKR.id === undefined ||
				selectedOKR.id === null ||
				krId === undefined ||
				krId === null
			)
				return;

			navigate(
				RoutePath.KR_DETAILS.replace(':okr', selectedOKR.id).replace(
					':kr',
					krId
				)
			);
		},
		[navigate, selectedOKR]
	);

	useEffect(() => {
		void dispatch(cleanKR());
		void dispatch(resetCheckpointState());
	}, [dispatch]);

	if (
		selectOKRStatus === 'loading' ||
		selectedOKR === undefined ||
		selectedOKR === null
	) {
		return (
			<Container>
				<Loader />;
			</Container>
		);
	}

	if (selectOKRStatus === 'failed') {
		navigate(RoutePath.DASHBOARD);
	}

	const okrDetail = (
		<div className="flex flex-col w-full p-4 pb-6 gap-4">
			<div className="flex flex-col gap-4">
				<div className="Callout h-10 p-2 rounded-[64px] border border-[#35373c] justify-between items-center inline-flex">
					{selectedOKR !== undefined && selectedOKR !== null && (
						<OKRIndexBadge okr={selectedOKR} okrs={okrs} />
					)}
					<div
						title={selectedOKR?.objective}
						className="TodaySTotalSales text-center text-white text-xs font-normal font-inter truncate"
					>
						{selectedOKR?.objective}
					</div>
					{selectedOKR !== undefined && selectedOKR !== null && (
						<ProgressBadge okr={selectedOKR} />
					)}
				</div>
			</div>
			{selectedOKR?.krs?.length === 0 && (
				<TutorialMessage>
					<p>Here you can keep track your objective progress 🎉</p>
					<p>Setup your milestones to share an update to your team later</p>
				</TutorialMessage>
			)}
			{selectedOKR?.krs?.map((kr) => (
				<div
					key={kr.id}
					className="Overview  p-2 bg-[#1b1c1f] rounded-lg border border-[#35373c] flex-col justify-start items-start gap-2 inline-flex cursor-pointer"
					onClick={() => onKRClick(kr.id)}
				>
					<div className="Frame626468 self-stretch justify-between items-start inline-flex">
						<div className="TodaySTotalSales px-1 text-white text-xs font-normal font-inter truncate">
							{kr.objective}
						</div>
						<AvatarGroup members={kr.followers ?? []} disabled />
					</div>
					<ProgressBar kr={kr} />
				</div>
			))}
			<div className="flex content-end justify-end ">
				<BlueButton type="button" onClick={addNewMilestoneHandler}>
					Set up a new milestone
				</BlueButton>
			</div>
		</div>
	);

	if (header === true) {
		return (
			<Container>
				<div className="mt-4 flex flex-col items-center border border-[#35373C] rounded-lg lg:w-3/4 m-auto">
					<div className="w-full p-2 pl-6">
						<BreadcrumbV2 />
					</div>
					{okrDetail}
				</div>
			</Container>
		);
	}

	return okrDetail;
};

export default OKRDetails;
