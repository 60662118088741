import { RoutePath } from 'index';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { logout, useIsAuthenticated, useName } from 'redux/slices/auth';
import { AppDispatch } from 'redux/store';

const Menu = () => {
	const dispatch = useDispatch<AppDispatch>();
	const isAuthenticated = useSelector(useIsAuthenticated());
	const name = useSelector(useName());
	const navigate = useNavigate();

	const onNavigateToSubscription = () => {
		navigate(RoutePath.SETTINGS_SUBSCRIPTION);
	};

	const onLogout = () => {
		void dispatch(logout());
	};

	if (!isAuthenticated) return null;

	return (
		<div className="dropdown dropdown-end">
			<label tabIndex={0} className="btn btn-ghost">
				<svg
					xmlns="http://www.w3.org/2000/svg"
					fill="white"
					viewBox="0 0 24 24"
					className="inline-block w-5 h-5 stroke-white"
				>
					<path
						strokeLinecap="round"
						strokeLinejoin="round"
						strokeWidth="3"
						d="M4 6h16M4 12h16M4 18h16"
					></path>
				</svg>
			</label>
			<ul
				tabIndex={0}
				className="menu menu-compact dropdown-content mt-3 p-2 shadow bg-base-100 rounded-box w-36"
			>
				{isAuthenticated && <div className="font-bold ml-4 my-2">@{name}</div>}
				<li className="pb-2">
					<button onClick={onNavigateToSubscription}>Subscription</button>
				</li>
				<li className="border-t pt-2">
					<button onClick={onLogout}>Logout</button>
				</li>
			</ul>
		</div>
	);
};

export default Menu;
