const OwnerSVG = () => {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width="16"
			height="16"
			viewBox="0 0 16 16"
			fill="none"
		>
			<path
				d="M8 1.33203C11.6825 1.33203 14.6667 4.31722 14.6667 7.9987C14.6667 11.6802 11.6825 14.6654 8 14.6654"
				stroke="#8D8F92"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M5.99967 14.3346C5.20743 14.0648 4.46322 13.6751 3.81502 13.1654M3.81502 2.8372C4.46322 2.33503 5.20743 1.93779 5.99967 1.66797M1.33301 6.83204C1.47705 6.06006 1.77313 5.31054 2.19726 4.62849M1.33301 9.17057C1.47705 9.94257 1.77313 10.692 2.19726 11.3741"
				stroke="#8D8F92"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M5.33301 11C6.71474 9.53467 9.26974 9.46573 10.6663 11M9.47827 6.5C9.47827 7.3284 8.81401 8 7.99467 8C7.17527 8 6.51106 7.3284 6.51106 6.5C6.51106 5.67157 7.17527 5 7.99467 5C8.81401 5 9.47827 5.67157 9.47827 6.5Z"
				stroke="#8D8F92"
				strokeLinecap="round"
			/>
		</svg>
	);
};

export default OwnerSVG;
