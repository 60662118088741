import useClickOutside from 'hooks/useClickOutsideComponent';
import CalendarSVG from 'icons/CalendarSVG';
import { useEffect, useRef, useState } from 'react';

interface RecurrenceTagProps {
	recurrenceValueRef: React.RefObject<HTMLInputElement>;
}

type UIMode = 'view' | 'selectingFrequency' | 'selectingDay';

const RecurrenceTag = ({ recurrenceValueRef }: RecurrenceTagProps) => {
	const componentRef = useRef<HTMLDivElement>(null);
	const [isSelecting, setIsSelecting] = useState<boolean>(false);
	const [frequency, setFrequency] = useState<
		'weekly' | 'biweekly' | 'monthly' | null
	>(null);
	const [dayOfWeek, setDayOfWeek] = useState<number | null>(null);
	const [uiMode, setUIMode] = useState<UIMode>('view');
	const [recurrence, setRecurrence] = useState<string>('');

	useClickOutside(componentRef, () => setIsSelecting(false));

	const toggleSelect = () => {
		setIsSelecting(!isSelecting);
		setUIMode('selectingFrequency');
	};

	const handleRecurrenceSelect = (
		recurrenceType: 'weekly' | 'biweekly' | 'monthly' | null
	) => {
		if (recurrenceType === null) {
			setFrequency(null);
			setDayOfWeek(null);
			setRecurrence('');
			setIsSelecting(false);
			setUIMode('view');
		} else {
			setFrequency(recurrenceType);
			setUIMode('selectingDay');
		}
	};

	const handleDaySelect = (day: number | null) => {
		if (frequency === null) return;
		if (day === null) return;

		// Generate rrule based on frequency and day of week
		// The hour and minute are hardcoded to 8:00 AM today
		// This can be changed to a user input or a user setting in the future
		const rrule = `FREQ=${frequency.toUpperCase()};BYDAY=${
			['SU', 'MO', 'TU', 'WE', 'TH', 'FR', 'SA'][day]
		};BYHOUR=8;BYMINUTE=0`;

		setDayOfWeek(day);
		setRecurrence(rrule);
		setIsSelecting(false);
		setUIMode('view');
	};

	useEffect(() => {
		if (recurrenceValueRef.current != null) {
			recurrenceValueRef.current.value = recurrence;
			recurrenceValueRef.current.dispatchEvent(
				new Event('change', { bubbles: true })
			);
		}
	}, [recurrenceValueRef, recurrence]);

	const recurrenceOptions = [
		{
			value: 'weekly',
			label: 'Weekly',
		},
		{
			value: 'biweekly',
			label: 'Biweekly',
		},
		{
			value: 'monthly',
			label: 'Monthly',
		},
	] as const;
	const weekDays = [
		'Sunday',
		'Monday',
		'Tuesday',
		'Wednesday',
		'Thursday',
		'Friday',
		'Saturday',
	];

	// extract a description from rrule
	const frequencyDescription = () => {
		if (recurrence === undefined || recurrence === null || recurrence === '') {
			return 'Follow-Up Cycle';
		}

		const [freq, byday] = recurrence.split(';');
		// eslint-disable-next-line @typescript-eslint/no-unused-vars
		const [_, frequency] = freq.split('=');
		// eslint-disable-next-line @typescript-eslint/no-unused-vars
		const [__, day] = byday.split('=');

		const dayIndex = ['SU', 'MO', 'TU', 'WE', 'TH', 'FR', 'SA'].indexOf(day);

		return `${
			frequency.charAt(0).toUpperCase() + frequency.slice(1).toLowerCase()
		} on ${weekDays[dayIndex].slice(0, 3)}`;
	};

	return (
		<div
			ref={componentRef}
			className="Tag w-fit p-1 cursor-pointer bg-zinc-800 rounded-lg border border-neutral-700 justify-start items-center gap-1 inline-flex"
		>
			<div className="Content px-1 justify-start items-center gap-1 flex">
				<input ref={recurrenceValueRef} type="hidden" value={recurrence} />
				<div className="UserStory w-4 h-4 relative" onClick={toggleSelect}>
					<CalendarSVG />
				</div>
				<div className="Owner text-neutral-400 text-xs font-normal font-['Inter'] leading-tight">
					<span onClick={toggleSelect}>{frequencyDescription()}</span>
					<div className={isSelecting ? 'relative' : 'hidden'}>
						<div
							className="Dropdown z-10 overflow-y-auto absolute -left-8 top-2 w-fit p-1 bg-zinc-900 rounded-lg shadow flex-col justify-start items-start gap-1 inline-flex"
							style={{
								scrollbarWidth: 'thin',
								scrollbarColor: '#D9D9D9 #1F2937',
							}}
						>
							{uiMode === 'selectingFrequency' &&
								recurrenceOptions.map((option) => (
									<div
										key={option.value}
										className={`DropdownOptions self-stretch pl-2 pr-1 py-2 rounded-md justify-start items-center gap-[42px] inline-flex cursor-pointer ${
											frequency === option.value ? 'bg-[#2A2C32]' : ''
										}`}
										onClick={() => handleRecurrenceSelect(option.value)}
									>
										<div className="SelectCompanySize text-[#d2d3e0] text-sm font-normal font-inter">
											{option.label}
										</div>
									</div>
								))}
							{uiMode === 'selectingDay' &&
								frequency !== null &&
								weekDays.map((day, index) => (
									<div
										key={day}
										className={`DropdownOptions self-stretch pl-2 pr-1 py-2 rounded-md justify-start items-center gap-[42px] inline-flex cursor-pointer ${
											dayOfWeek === index ? 'bg-[#2A2C32]' : ''
										}`}
										onClick={() => handleDaySelect(index)}
									>
										<div className="SelectCompanySize text-[#d2d3e0] text-[13px] font-normal font-['Inter']">
											{day}
										</div>
									</div>
								))}
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default RecurrenceTag;
