import { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { loadCompany, useCompanyState } from 'redux/slices/company';
import { AppDispatch } from 'redux/store';

export const Welcome = (): React.ReactElement => {
	const dispatch = useDispatch<AppDispatch>();
	const company = useSelector(useCompanyState());

	useEffect(() => {
		if (company.status === 'idle') {
			void dispatch(loadCompany());
		}
	}, [dispatch, company]);

	if (
		company.status === 'success' &&
		company.name !== undefined &&
		company.name !== null
	) {
		return (
			<div className="mb-4 flex flex-col md:items-start border border-[#35373C] rounded-lg">
				<div className="flex p-6 text-[#6B7280] text-sm leading-5 font-medium">
					<div className="pr-4">
						<img
							src={company.logo}
							alt={company.name}
							className="w-20 h-20 rounded-xl"
						/>
					</div>
					<div>
						<p>Welcome,</p>
						<p className="text-white text-2xl leading-8 font-semibold">
							{company.name}
						</p>
						<p>{company.numberOfEmployees} members</p>
					</div>
				</div>
			</div>
		);
	}

	return <></>;
};
