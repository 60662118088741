const NumberSVG = () => (
	<svg
		xmlns="http://www.w3.org/2000/svg"
		width="16"
		height="16"
		viewBox="0 0 16 16"
		fill="none"
	>
		<path
			d="M4.6665 6.66623V2.63208C4.6665 2.24907 4.6665 2.05757 4.5129 2.00991C4.17521 1.90513 3.6665 2.66602 3.6665 2.66602M4.6665 6.66623H3.6665M4.6665 6.66623H5.6665"
			stroke="#8D8F92"
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
		<path
			d="M6.00016 11.6673V10.5007C6.00016 9.95065 6.00016 9.67572 5.8049 9.50485C5.60964 9.33398 5.29537 9.33398 4.66683 9.33398C4.03829 9.33398 3.72402 9.33398 3.52876 9.50485C3.3335 9.67572 3.3335 9.95065 3.3335 10.5007C3.3335 11.0507 3.3335 11.3256 3.52876 11.4965C3.72402 11.6673 4.03829 11.6673 4.66683 11.6673H6.00016ZM6.00016 11.6673V12.2507C6.00016 13.0756 6.00016 13.4881 5.70727 13.7444C5.41438 14.0007 4.94297 14.0007 4.00016 14.0007H3.3335"
			stroke="#8D8F92"
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
		<path
			d="M11.0002 13.3327V2.66602M11.0002 13.3327C10.5334 13.3327 9.66116 12.0031 9.3335 11.666M11.0002 13.3327C11.467 13.3327 12.3392 12.0031 12.6668 11.666"
			stroke="#8D8F92"
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
	</svg>
);

export default NumberSVG;
