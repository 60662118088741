import axiosInstance from 'data/fetcher';
import useClickOutside from 'hooks/useClickOutsideComponent';
import TeamSVG from 'icons/TeamSVG';
import { useEffect, useRef, useState } from 'react';
import { API_ROUTES } from 'routes';
import { ITeamMember } from 'types';
import MemberDropdown from './MemberDropDown';
import AvatarGroup from 'components/avatars/AvatarGroup';

interface TeamTagProps {
	memberIds?: string[];
	onChangeMembers: (members: ITeamMember[]) => void;
}

const TeamTag = (props: TeamTagProps) => {
	const { onChangeMembers } = props;
	const [memberList, setMemberList] = useState<ITeamMember[]>([]);
	const [selectedMembers, setSelectedMembers] = useState<ITeamMember[]>([]);
	const [loading, setLoading] = useState<boolean>(false);
	const [isSelecting, setIsSelecting] = useState<boolean>(false);
	const componentRef = useRef<HTMLDivElement>(null);

	const toggleSelect = () => {
		setIsSelecting(!isSelecting);
	};

	useClickOutside(componentRef, () => setIsSelecting(false));

	useEffect(() => {
		setLoading(true);
		const loadMembers = async () => {
			const { data } = await axiosInstance.get(API_ROUTES.CORE_MEMBERS_LIST);
			setMemberList(data.members);
			setLoading(false);
		};
		void loadMembers();
	}, []);

	const toggleMemberSelection = (member: ITeamMember) => {
		const isSelected = selectedMembers.some((m) => m.id === member.id);
		let updatedSelectedMembers;
		if (isSelected) {
			updatedSelectedMembers = selectedMembers.filter(
				(m) => m.id !== member.id
			);
		} else {
			updatedSelectedMembers = [...selectedMembers, member];
		}
		setSelectedMembers(updatedSelectedMembers);
		onChangeMembers(updatedSelectedMembers);
	};

	return (
		<div
			ref={componentRef}
			className="Tag p-1 cursor-pointer bg-zinc-800 rounded-lg border border-neutral-700 justify-start items-center gap-1 inline-flex"
		>
			<div className="relative">
				{selectedMembers.length > 0 ? (
					<AvatarGroup members={selectedMembers} toggleSelect={toggleSelect} />
				) : (
					<div className="SelectMembers px-1 justify-start items-center gap-1 flex">
						<div className="UserStory w-4 h-4 relative" onClick={toggleSelect}>
							<TeamSVG />
						</div>
						<div className="Owner text-neutral-400 text-xs font-normal font-['Inter'] leading-tight">
							<span onClick={toggleSelect}>Team</span>
						</div>
					</div>
				)}
				{isSelecting && (
					<div className="relative">
						<MemberDropdown
							memberList={memberList}
							loading={loading}
							selectedMembers={selectedMembers}
							toggleMemberSelection={toggleMemberSelection}
						/>
					</div>
				)}
			</div>
		</div>
	);
};

export default TeamTag;
