import { OKR } from 'types';

interface BadgeProps {
	okr: OKR;
	okrs: OKR[];
}

const OKRIndexBadge = (props: BadgeProps) => {
	const { okr, okrs } = props;
	const okrIndex = okrs.map((x) => x.id).indexOf(okr.id) + 1;
	return (
		<div className="flex flex-row gap-2">
			<div className="Badge px-3 py-0.5 bg-[#2a2c32] rounded-[15px] justify-start items-center flex">
				<div className="Text text-white text-xs font-medium font-['Inter'] uppercase leading-tight tracking-tight">
					O{okrIndex}
				</div>
			</div>
		</div>
	);
};

export default OKRIndexBadge;
