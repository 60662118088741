import React, { useCallback, useEffect, useRef } from 'react';
import { useDispatch } from 'react-redux';
import { AppDispatch } from 'redux/store';
import BlueButton from 'components/BlueButton';
import OwnerTag from 'components/Forms/OwnerTag';
import { useNavigate } from 'react-router-dom';
import Container from 'pages/authenticated/Container';
import { ITeamMember, KR } from 'types';
import useSelectedOKR from 'hooks/useSelectedOKR';
import ProgressBadge from 'components/okr/ProgressBadge';
import OKRIndexBadge from 'components/okr/OKRIndexBadge';
import MetricsCard from 'components/milestone/MetricCard';
import TeamTag from 'components/Forms/TeamTag';
import RecurrenceTag from 'components/Forms/RecurrenceTag';
import { addOrUpdateKR, selectOKR } from 'redux/slices/okrs';
import { RoutePath } from 'index';
import BreadcrumbV2 from 'components/BreadcrumbV2';
import { Loader } from 'components/loader/Loader';
import * as amplitude from '@amplitude/analytics-browser';

interface AddMilestoneProps {
	header?: boolean;
}

const AddMilestone = (props: AddMilestoneProps): React.ReactElement => {
	const { header } = props;
	const navigate = useNavigate();
	const dispatch = useDispatch<AppDispatch>();
	const { selectedOKR, selectOKRStatus, okrs } = useSelectedOKR();
	const titleRef = useRef<HTMLTextAreaElement>(null);
	const initialValueRef = useRef<HTMLInputElement>(null);
	const metricTypeRef = useRef<HTMLInputElement>(null);
	const targetValueRef = useRef<HTMLInputElement>(null);
	const [owner, setOwner] = React.useState<ITeamMember>();
	const [members, setMembers] = React.useState<ITeamMember[]>();
	const recurrenceValueRef = useRef<HTMLInputElement>(null);
	const [isButtonDisabled, setIsButtonDisabled] = React.useState(true);

	const onSelectOwnerHandler = (selected: ITeamMember | undefined) => {
		setOwner(selected);
	};

	const validateForm = useCallback(() => {
		const isTitleFilled = titleRef.current?.value.trim() !== '';
		const isInitialValueFilled = initialValueRef.current?.value.trim() !== '';
		const isMetricTypeSelected = metricTypeRef.current?.value.trim() !== '';
		const isTargetValueFilled = targetValueRef.current?.value.trim() !== '';
		const isRecurrenceFilled = recurrenceValueRef.current?.value.trim() !== '';
		const isOwnerSelected = owner !== undefined;
		const isMembersSelected = members !== undefined && members.length > 0;

		const validation =
			isTitleFilled &&
			isInitialValueFilled &&
			isMetricTypeSelected &&
			isTargetValueFilled &&
			isRecurrenceFilled &&
			isOwnerSelected &&
			isMembersSelected;

		return validation;
	}, [owner, members]);

	useEffect(() => {
		const handleInputChange = () => {
			setIsButtonDisabled(!validateForm());
		};

		const titleElement = titleRef.current;
		const initialValueElement = initialValueRef.current;
		const metricTypeElement = metricTypeRef.current;
		const targetValueElement = targetValueRef.current;
		const recurrenceValueElement = recurrenceValueRef.current;

		titleElement?.addEventListener('input', handleInputChange);
		initialValueElement?.addEventListener('input', handleInputChange);
		metricTypeElement?.addEventListener('change', handleInputChange);
		targetValueElement?.addEventListener('input', handleInputChange);
		recurrenceValueElement?.addEventListener('change', handleInputChange);

		handleInputChange(); // Initial validation check

		return () => {
			titleElement?.removeEventListener('input', handleInputChange);
			initialValueElement?.removeEventListener('input', handleInputChange);
			metricTypeElement?.removeEventListener('change', handleInputChange);
			targetValueElement?.removeEventListener('input', handleInputChange);
			recurrenceValueElement?.removeEventListener('change', handleInputChange);
		};
	}, [owner, members, validateForm]);

	const onSubmit = async () => {
		if (selectedOKR?.id === undefined || selectedOKR?.id === null) {
			return;
		}

		if (
			titleRef.current?.value === undefined ||
			titleRef.current?.value === null
		) {
			return;
		}

		if (
			initialValueRef.current?.value === undefined ||
			initialValueRef.current?.value === null
		) {
			return;
		}

		if (
			targetValueRef.current?.value === undefined ||
			targetValueRef.current?.value === null
		) {
			return;
		}

		if (owner === undefined || owner === null) {
			return;
		}

		const type = metricTypeRef.current?.value;
		const metricTypeValue = type === '$' ? '$' : type === '%' ? '%' : '123';

		const kr: KR = {
			status: 'active',
			objective: titleRef.current?.value,
			current: parseFloat(initialValueRef.current?.value),
			target: parseFloat(targetValueRef.current?.value),
			type: metricTypeValue,
			owner: owner.id,
			recurrence_rule: recurrenceValueRef.current?.value,
			followers: members?.map((member) => {
				return {
					id: member.id,
					name: '',
					profile_image: '',
					profile_title: '',
					status: 'active',
				};
			}),
		};

		await dispatch(addOrUpdateKR({ kr, okrId: selectedOKR?.id }));
		void amplitude.track('New Milestone', {
			title: kr.objective,
			owner: kr.owner,
			recurrence: kr.recurrence_rule,
		});

		void dispatch(
			selectOKR({
				okrId: selectedOKR.id,
			})
		).then((args) => {
			navigate(`${RoutePath.OKR_DETAILS.replace(':okr', args.payload.id)}`);
		});
	};

	if (
		selectOKRStatus === 'loading' &&
		(selectedOKR === undefined || selectedOKR === null)
	) {
		return <Loader />;
	}

	if (selectOKRStatus === 'failed') {
		navigate(RoutePath.DASHBOARD);
	}

	const form = (
		<div className="w-full pb-6 ">
			<div className="m-4 flex flex-col gap-4">
				<div className="VerticalNavigationItem h-10 pl-2 pr-3 py-2 bg-[#1b1c1f] rounded-md justify-start items-center inline-flex">
					<div className="Content justify-start items-center gap-3 flex">
						<div className="Target02 w-6 h-6 relative">
							<svg
								xmlns="http://www.w3.org/2000/svg"
								width="24"
								height="24"
								viewBox="0 0 24 24"
								fill="none"
							>
								<path
									d="M17 12C17 14.7614 14.7614 17 12 17C9.23858 17 7 14.7614 7 12C7 9.23858 9.23858 7 12 7"
									stroke="#6B7280"
									strokeWidth="1.5"
									strokeLinecap="round"
								/>
								<path
									d="M14 2.20004C13.3538 2.06886 12.6849 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22C17.5228 22 22 17.5228 22 12C22 11.3151 21.9311 10.6462 21.8 10"
									stroke="#6B7280"
									strokeWidth="1.5"
									strokeLinecap="round"
								/>
								<path
									d="M12.0303 11.9624L16.5832 7.40949M19.7404 4.34451L19.1872 2.35737C19.0853 2.03 18.6914 1.89954 18.4259 2.11651C16.9898 3.29007 15.4254 4.8708 16.703 7.36408C19.2771 8.56443 20.7466 6.94573 21.8733 5.58519C22.0975 5.31449 21.9623 4.90756 21.6247 4.80994L19.7404 4.34451Z"
									stroke="#6B7280"
									strokeWidth="1.5"
									strokeLinecap="round"
									strokeLinejoin="round"
								/>
							</svg>
						</div>
						<div className="Text text-gray-500 text-sm font-medium font-['Inter'] leading-tight">
							New Milestone
						</div>
					</div>
				</div>
				<BreadcrumbV2 title="New Milestone" />
				<div className="Callout h-10 p-2 rounded-[64px] border border-[#35373c] justify-between items-center inline-flex">
					{selectedOKR !== undefined && selectedOKR !== null && (
						<OKRIndexBadge okr={selectedOKR} okrs={okrs} />
					)}
					<div className="TodaySTotalSales w-[155px] text-center text-white text-xs font-normal font-['Inter'] truncate">
						{selectedOKR?.objective}
					</div>
					{selectedOKR !== undefined && selectedOKR !== null && (
						<ProgressBadge okr={selectedOKR} />
					)}
				</div>
				<div className="Label text-[#e5e5ea] text-sm font-medium font-inter leading-tight">
					Metrics
				</div>
				<MetricsCard
					titleRef={titleRef}
					initialValueRef={initialValueRef}
					metricTypeRef={metricTypeRef}
					targetValueRef={targetValueRef}
				/>
				<div className="flex gap-2 flex-wrap">
					<OwnerTag onSelect={onSelectOwnerHandler} />
					<TeamTag onChangeMembers={setMembers} />
					<RecurrenceTag recurrenceValueRef={recurrenceValueRef} />
				</div>
				<div className="Divider w-ful h-px bg-neutral-700 border-neutral-700" />
				<div className="flex content-end justify-end">
					<BlueButton
						type="button"
						disabled={isButtonDisabled}
						onClick={onSubmit}
					>
						Add Objective
					</BlueButton>
				</div>
			</div>
		</div>
	);

	if (header === true) {
		return (
			<Container>
				<div className="mt-4 flex flex-col items-center border border-[#35373C]  rounded-lg lg:w-3/4 m-auto">
					{form}
				</div>
			</Container>
		);
	}

	return form;
};

export default AddMilestone;
