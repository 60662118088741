import { useState } from 'react';
import useTimer from 'hooks/useTimer';

const hiddenSlideStyles: React.CSSProperties = {
	borderRadius: '2rem',
	position: 'absolute',
	top: 0,
	left: -900,
	opacity: 0,
};

const shownSlideStyles: React.CSSProperties = {
	borderRadius: '2rem',
	position: 'absolute',
	top: 0,
	left: -800,
	opacity: 1,
	boxShadow: '0px 0px 10px rgba(255, 255, 255, 0.5)', // Add this line for shadow
};

const sliderStyles: React.CSSProperties = {
	position: 'relative',
	height: '100%',
};

interface Slide {
	title?: string;
	url?: string;
}

interface TimedSliderProps {
	slides: Slide[];
}

const TimedSlider = ({ slides }: TimedSliderProps) => {
	const [currentIndex, setCurrentIndex] = useState(0);
	const goToNext = () => {
		if (currentIndex < slides.length - 1) {
			setCurrentIndex(currentIndex + 1);
		} else {
			setCurrentIndex(0);
		}
	};

	const { pause, resume } = useTimer({
		interval: 5000,
		onTick: goToNext,
	});

	const goToSlide = (slideIndex: number) => {
		if (currentIndex !== slideIndex) {
			setCurrentIndex(slideIndex);
		}
	};

	const mouseOverNavigation = () => {
		pause();
	};
	const mouseLeaveNavigation = () => {
		resume();
	};

	return (
		<div className="Slider h-96 w-full hidden xl:flex flex-col justify-start items-end">
			<div className="Navigation w-full pr-48 2xl:px-40 pt-8 pb-2 justify-end items-center gap-5 inline-flex">
				{slides.map((_, slideIndex) => (
					<div
						key={`navigation-${slideIndex}`}
						onClick={() => goToSlide(slideIndex)}
						className="w-36 h-1 relative cursor-pointer pt-2 pb-4 mt-2 mb-2 justify-center content-center"
						onMouseOver={mouseOverNavigation}
						onMouseLeave={mouseLeaveNavigation}
					>
						<div>
							<div className="Rectangle828059 h-1 left-0 top-0 absolute bg-zinc-700 rounded-lg my-2 w-36 " />
							<div
								className="Rectangle828061 h-1 left-0 top-0 absolute bg-white rounded-lg my-2 transition-all duration-500"
								style={{
									width: currentIndex >= slideIndex ? '9rem' : '0',
								}}
							/>
						</div>
					</div>
				))}
			</div>
			<div className="Frame626464 flex">
				<div className="flex flex-wrap">
					<div style={sliderStyles}>
						{slides.map((slide, slideIndex) => (
							<img
								key={slideIndex}
								src={slide.url}
								alt={slide.title}
								onMouseOver={mouseOverNavigation}
								onMouseLeave={mouseLeaveNavigation}
								className="max-h-[740px] max-w-[740px] 2xl:max-h-[800px] 2xl:max-w-[800px] transition-all duration-700"
								style={
									currentIndex === slideIndex
										? shownSlideStyles
										: hiddenSlideStyles
								}
							/>
						))}
					</div>
				</div>
			</div>
		</div>
	);
};

export default TimedSlider;
